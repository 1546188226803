import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {Page} from "../../_model/page-dto";
import {UsuarioDto} from "../../admin/cadastros/usuario/model/usuario-dto";

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  url = environment.api.url + '/usuario'
  constructor(
    public http: HttpClient
  ) { }

  listarTodosPaginado(page: number, size: number): Observable<Page<UsuarioDto>> {
    let params = new HttpParams()
      .append('page', page)
      .append('size', size)
    return this.http.get<Page<UsuarioDto>>(this.url + '/pagina-ativos', {params})
  }

  listarTodos(): Observable<UsuarioDto[]> {
    return this.http.get<UsuarioDto[]>(this.url + '/lista-ativos')
  }

  buscarPeloId(id: number): Observable<UsuarioDto> {
    return this.http.get<UsuarioDto>(this.url + '/' + id);
  }

  enviarFormulario(body: any, id?: number | null): Observable<any> {
    if (id) {
      return this.http.put(this.url + '/' + id, body);
    }
    return this.http.post(this.url, body);
  }

  mudaSenha(body: any): Observable<any> {
      return this.http.put(this.url + '/senha/alterar', body);
  }


}
