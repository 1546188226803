import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {Page} from "../../_model/page-dto";
import { MunicipioDto } from 'src/app/admin/cadastros/municipio/_model/municipio-dto';

@Injectable({
  providedIn: 'root'
})
export class MunicipioService {
  url = environment.api.url + '/municipio'
  constructor(
    public http: HttpClient
  ) { }

  listarMunicipioPaginado(page: number, size: number): Observable<Page<MunicipioDto>> {
    let params = new HttpParams()
      .append('page', page)
      .append('size', size);
    return this.http.get<Page<MunicipioDto>>(this.url + '/pagina-ativos', {params})
  }

  listarMunicipio(): Observable<MunicipioDto[]> {
    return this.http.get<MunicipioDto[]>(this.url + '/lista-ativos')
  }

  buscarPeloId(id: number): Observable<MunicipioDto> {
    return this.http.get<MunicipioDto>(this.url + '/' + id);
  }

  enviarFormulario(body: any, id?: number | null): Observable<any> {
    if (id) {
      return this.http.put(this.url + '/' + id, body);
    }
    return this.http.post(this.url, body);
  }
}
