import { Component, OnInit } from '@angular/core';
import {MensagemService} from "../../../../_services/mensagem.service";
import {LoadingConstant} from "../../../../_model/loading-constant";
import {PaginatorDto} from "../../../../_componentes/tabela/tabela.component";
import {EventoDto} from "../model/evento-dto";
import {EventoService} from "../../../../_services/web/evento.service";
import {StakeholderService} from "../../../../_services/web/stakeholder.service";
import {ActivatedRoute, Router} from "@angular/router";
import { StakeholderDto } from '../../stakeholder/_model/stakeholder-dto';
import {FormBuilder, Validators} from "@angular/forms";
import {ListaAuxiliarDto} from "../../../../_model/lista-auxiliar-dto";
import {forkJoin} from "rxjs";
import {TipoParticipacaoService} from "../../../../_services/web/tipo-participacao.service";

@Component({
  selector: 'app-visualizacao-evento',
  templateUrl: './visualizacao-evento.component.html',
  styleUrls: ['./visualizacao-evento.component.scss']
})
export class VisualizacaoEventoComponent implements OnInit {

  lista: StakeholderDto[] = [];
  icone = 'fa-comments';
  contextoRota = 'evento';
  descricao= 'Descrição bichona do evento, vai ser recebida aqui!';
  dadosEvento : EventoDto = new EventoDto();
  pagina = 0;
  tamanho = 7;
  totalItens = 0;
  formulario = this.formBuilder.group({
    nome: ['', Validators.required, Validators.minLength(2)],
  });
  stakeholders: StakeholderDto[] = [];
  tiposParticipacao: ListaAuxiliarDto[] = [];
  tipoParticipacao: number = 0
  constructor(
    public eventoService: EventoService,
    public stakeholderService: StakeholderService,
    public mensagemService: MensagemService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public formBuilder: FormBuilder,
    public tipoParticipacaoService: TipoParticipacaoService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.id) {
        this.carregarDados(params.id);
      }
    });
  }

  carregarDados(id: number) {
    LoadingConstant.loading = true;
    this.eventoService.buscarPeloId(id).subscribe({
      next: (response) => {
        this.dadosEvento=response;
        this.listarTodos(id);
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }

  listarTodos(idEvento?: number): void {
    if (!idEvento) {
      idEvento = this.dadosEvento.id;
    }
    LoadingConstant.loading = true;
    forkJoin(
      [this.stakeholderService.listarStakeholdersDoEvento(this.pagina, this.tamanho, idEvento),
        this.tipoParticipacaoService.listarTodos(),
      ]
    ).subscribe({
      next: ([stakeholders, tipos]) => {
        this.lista = stakeholders.content;
        this.totalItens = stakeholders.totalElements;
        this.tiposParticipacao = tipos;
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    });
  }

  paginacao(evento: PaginatorDto): void {
    this.pagina = evento.pagina;
    this.tamanho = evento.tamanho;
    this.listarTodos(this.dadosEvento.id);
  }

  voltarLista() {
    this.router.navigate(['/admin/cadastros/evento'])
  }

  edita() {
    if(this.dadosEvento != null){
      this.router.navigate([`/admin/cadastros/${this.contextoRota}/editar`, this.dadosEvento.id]);
    }
  }

  downloadList(){
    LoadingConstant.loading = true;
    this.stakeholderService.downloadDocumento(this.dadosEvento.id).subscribe({
      next: (response) => {
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(response);
        link.setAttribute('download', 'lista_presenca_evento_'+ this.dadosEvento.nome);
        document.body.appendChild(link);
        LoadingConstant.loading = false;
        link.click();
        document.body.removeChild(link);
      }, error: (error) => {
        LoadingConstant.loading = false;
        this.mensagemService.error(error);
      }
    });
  }

  pesquisarStakeholders() {
    if (this.formulario.controls.nome.value != null) {
      LoadingConstant.loading = true;
      this.stakeholderService.listarStakeholder(this.formulario.controls.nome.value, this.dadosEvento.id).subscribe({
        next: (response) => {
          this.stakeholders = response;
        },
        error: (error) => {
          this.mensagemService.error(error);
        }
      }).add(() => {
        LoadingConstant.loading = false;
      });
    }
  }

  adicionarStakeholder(idStakeholder: number) {
    if (this.tipoParticipacao == 0) {
      this.mensagemService.errorMensagem('É necessário selecionar um tipo de participacao');
      return;
    }
    LoadingConstant.loading = true;
    this.stakeholderService.atrelaStakeholderEvento(idStakeholder, this.dadosEvento.id, this.tipoParticipacao).subscribe({
      next: () => {
        this.mensagemService.sucesso('Stakeholder adicionado com sucesso ao Evento');
        this.listarTodos(this.dadosEvento.id)
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add(() => {
      LoadingConstant.loading = false;
    });
  }

  remover(idStakeholder: number) {
    LoadingConstant.loading = true;
    this.stakeholderService.removeStakeholderEvento(idStakeholder, this.dadosEvento.id).subscribe({
      next: () => {
        this.mensagemService.sucesso('Stakeholder removido com sucesso do Evento');
        this.listarTodos(this.dadosEvento.id)
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add(() => {
      LoadingConstant.loading = false;
    });
  }

  abrirFormularioStakeholder() {
    this.router.navigate(["/admin/cadastros/stakeholder/novo", this.dadosEvento.id])
  }
}
