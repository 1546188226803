import {Component, OnInit} from '@angular/core';
import {LoadingConstant} from "../../../../_model/loading-constant";
import {MensagemService} from "../../../../_services/mensagem.service";
import {PaginatorDto} from "../../../../_componentes/tabela/tabela.component";
import {UsuarioService} from "../../../../_services/web/usuario.service";
import {UsuarioDto} from "../model/usuario-dto";

@Component({
  selector: 'app-lista-usuario',
  templateUrl: './lista-usuario.component.html',
  styleUrls: ['./lista-usuario.component.scss']
})
export class ListaUsuarioComponent implements OnInit {
  lista: UsuarioDto[] = [];
  icone = 'fa-user';
  contextoRota = 'usuario';
  titulo = 'Usuários';
  pagina = 0;
  tamanho = 7;
  totalItens = 0;

  constructor(
    public usuarioService: UsuarioService,
    public mensagemService: MensagemService
  ) { }

  ngOnInit(): void {
    this.listarTodos();
  }

  listarTodos(): void {
    LoadingConstant.loading = true;
    this.usuarioService.listarTodosPaginado(this.pagina, this.tamanho).subscribe({
      next: (response) => {
        this.lista = response.content;
        this.totalItens = response.totalElements;
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    });
  }

  abrirPopupDados(id: number) {

  }

  paginacao(evento: PaginatorDto): void {
    this.pagina = evento.pagina;
    this.tamanho = evento.tamanho;
    this.listarTodos();
  }
}
