<div class="row w-100 pagina-titulo">
  <div class="col-12 flex flex-column flex-md-row justify-content-beetwen align-items-md-start topo-lista hr-bottom">
    <div class="flex align-items-start ">
      <i class="far color-primario {{icone}} icone-grande"></i>
      <div class="flex flex-column">
        <div>
          <h2 class="titulo-pagina color-primario ">{{dadosEvento.nome}}</h2>
        </div>
        <div>
          <p>{{dadosEvento.descricao}}</p>
        </div>
      </div>
    </div>
    <div class="flex justify-content-end botao-titulo cursor-pointer ">
      <button class="bg-branco botao-acao-lista muda-cor">
        <i class="fas fa-pen"></i>
        <span class="texto-botao " (click)="edita()">editar</span>
      </button>
      <button class="bg-branco botao-acao-lista cursor-pointer muda-cor">
        <i class="fas fa-angle-left"></i>
        <span (click)="voltarLista()" class="texto-botao">voltar</span>
      </button>
    </div>
  </div>
  <div class="flex col-12">
    <div class="">
      <i class="icone-pequeno fas fa-industry"></i>
      <span class="texto-botao color-texto">{{dadosEvento.financiador}}</span>
    </div>
    <div class="texto-icone">
      <i class="icone-pequeno fas fa-map-marker-alt"></i>
      <span class="texto-botao color-texto">{{dadosEvento.local}}</span>
    </div>
    <div class="texto-icone">
      <i class="icone-pequeno far fa-calendar-plus color-black"></i>
      <span class="texto-botao color-texto">{{dadosEvento.dataInicio | date: 'dd/MM/yyyy'}}</span>
    </div>
    <div class="texto-icone">
      <i class="icone-pequeno far fa-calendar-minus color-black"></i>
      <span class="texto-botao color-texto">{{dadosEvento.dataFim | date: 'dd/MM/yyyy'}}</span>
    </div>
  </div>

  <div class="col-md-7 col-12 mt-5">
    <h2 class="color-primario titulo-pagina">
      Presenças Confirmadas
    </h2>
    <button class="bg-primario color-white cursor-pointer muda-bg mt-2 mb-2" (click)="downloadList()">Lista XLS <i class="fa fa-download" aria-hidden="true"></i></button>
    <div class="form-group form-check hr-bottom" *ngFor="let stakeholder of lista">
      <div class="flex mt-3 justify-content-beetwen">
        <div class="w-75 flex">
          <i class=" icone-pequeno fas fa-user-tie color-primario texto-botao"></i>
          <h4 class="texto-botao">{{stakeholder.nome}} - {{stakeholder.tipoParticipacao}}</h4>
        </div>
        <div class="w-25 flex justify-content-end">
          <i class=" icone-pequeno fas fa-trash cursor-pointer color-primario texto-botao" (click)="remover(stakeholder.id)"></i>
        </div>
      </div>
    </div>
    <app-paginator [totalElementos]="totalItens" [pagina]="pagina" [tamanho]="tamanho" [exibirSelecaoTamanho]="false" (paginacao)="paginacao($event)"></app-paginator>
  </div>


  <div class="col-md-5 col-12 mt-5">
    <div class="flex justify-content-beetwen align-items-center">
      <div>
        <h2 class="color-primario titulo-pagina">
          Realizar
        </h2>
        <h5 class="color-primario">
          Chamada
        </h5>
      </div>
      <div>
        <button class=" bg-primario color-white cursor-pointer muda-bg botao-adicionar" (click)="abrirFormularioStakeholder()">novo participante</button>
      </div>
    </div>

    <form [formGroup]="formulario">
      <div class="tabela-lista flex">
        <input class="input-pesquisa" type="text" name="nome" id="nome" formControlName="nome">
        <button class="bg-primario botao-stakeholder cursor-pointer muda-bg" (click)="pesquisarStakeholders()">
          <i class="fas fa-search color-white icone-pequeno"></i>
        </button>
      </div>
    </form>
    <div class="flex justify-content-beetwen align-items-center mt-3">
      <h4 class="mt-3 color-texto w-50">Resultado da busca:</h4>
      <select class="form-control" id="idTipoParticipacao" [(ngModel)]="tipoParticipacao">
        <option value="0">Selecione um tipo de participação</option>
        <option *ngFor="let tipo of tiposParticipacao" [value]="tipo.id">{{tipo.descricao}}</option>
      </select>
    </div>
    <div class="form-group row  hr-bottom" *ngFor="let stakeholder of stakeholders">
      <div class="flex mt-3">
        <i class=" icone-pequeno fas fa-user-tie color-primario texto-botao"></i>
        <h4 class="texto-botao">{{stakeholder.nome}}</h4>
        <a (click)="adicionarStakeholder(stakeholder.id)" class="cursor-pointer">
          <i class=" icone-pequeno fas fa-plus color-primario texto-botao"></i>
        </a>
      </div>
    </div>
  </div>
</div>
