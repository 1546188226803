import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Router} from "@angular/router";

export interface PaginatorDto {
  pagina: number;
  tamanho: number
}

@Component({
  selector: 'app-tabela',
  templateUrl: './tabela.component.html',
  styleUrls: ['./tabela.component.scss']
})
export class TabelaComponent implements OnInit, OnChanges {
  @Input() itens: any[] = [];
  @Input() itensLista: any[] = [];
  @Input() camposExibidos: {icone: string, titulo: string, nome: string}[] = []
  @Input() icone: string = '';
  @Input() contextoRota: string = '';
  @Input() exibirVisualizar = true;
  @Input() exibirIconeVisualizar = true;
  @Input() textoVisualizar = 'visualizar';
  @Input() exibirEditar = true;
  @Input() textoEditar = 'editar';
  @Input() marginTopVisualizar = false;
  @Input() marginBottomEditar = false;
  @Input() exibirTextoEditar = true;
  @Input() acaoEditarPadrao = true;
  @Input() exibirRemover = false;
  @Input() exibirUltimosAdicionados = true;
  @Input() exibirExpandir = false;
  @Input() exibirDescricao = true;
  @Input() textoSubtitulo = 'Últimos Adicionados';
  @Input() idSelecionado = null;
  @Input() alternarCoresLinhas = false;
  @Input() outrasColunas: {campos: string[], titulos: string[]} = {campos: [], titulos: []};
  @Input() espacamentoLinhas = true;
  @Output() visualizar = new EventEmitter<{id: number}>();
  @Output() editar = new EventEmitter<any>();
  @Output() remover = new EventEmitter<{index: number}>();

  // utilizados na paginação
  @Input() paginado = false;
  @Input() listaTamanho: number[] = [15, 25, 100]
  @Input() pagina = 0;
  @Input() exibirSelecaoTamanho: boolean = true;
  @Output() paginacao: EventEmitter<PaginatorDto> = new EventEmitter;
  @Input() totalElementos = 0;
  @Input() tamanho: number = 5;
  @Input() tipoPaginacao: 'backend' | 'frontend' = 'backend';

  itensPaginados: any[] = [];

  constructor(
    public router: Router
  ) { }

  ngOnInit(): void {
    if (this.tipoPaginacao === 'frontend') {
      this.paginarFrontend();
    }
  }

  edita(item: any) {
    if (this.acaoEditarPadrao) {
      this.router.navigate([`/admin/cadastros/${this.contextoRota}/editar`, item.id]);
    } else {
      this.editar.emit(item);
    }
  }

  visualiza(item: any) {
    this.visualizar.emit(item);
  }

  remove(index: number) {
    index = index + (this.pagina * this.tamanho);
    this.remover.emit({index});
  }

  pageEmit(paginacao: PaginatorDto) {
    if (this.tipoPaginacao === 'backend') {
      this.paginacao.emit(paginacao);
    } else {
      this.pagina = paginacao.pagina;
      this.tamanho = paginacao.tamanho;
      this.paginarFrontend();
    }
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['itens'] && this.tipoPaginacao === 'frontend') {
      this.paginarFrontend();
    }
  }

  private paginarFrontend() {
    const start = this.pagina * this.tamanho;
    const end = start + this.tamanho;
    if (this.itens) {
      this.itensPaginados = this.itens.slice(start, end);
      this.totalElementos = this.itens.length;
      if (this.itensPaginados.length === 0 && this.itens.length > 0) {
        this.pagina = this.pagina - 1;
        this.paginarFrontend();
      }
    }
  }

  expandir(id: number) {
    let item = this.itens.find(item => item.id == id);
    item.exibirInfo = !item.exibirInfo;
  }
}
