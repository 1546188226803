import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {LoadingConstant} from "../../../../_model/loading-constant";
import {UsuarioService} from "../../../../_services/web/usuario.service";
import {MensagemService} from "../../../../_services/mensagem.service";
import {ActivatedRoute, Router} from "@angular/router";
import {forkJoin} from "rxjs";
import {PerfilService} from "../../../../_services/web/perfil.service";

@Component({
  selector: 'app-formulario-usuario',
  templateUrl: './formulario-usuario.component.html',
  styleUrls: ['./formulario-usuario.component.scss']
})
export class FormularioUsuarioComponent implements OnInit {

  perfis: any[] = []
  titulo = 'Usuário';
  contextoRota = 'usuario';
  icone = 'fa-user';
  id = null;
  abrirModal = false;
  formulario = this.formBuilder.group({
    nome: ['', Validators.maxLength(100)] ,
    idPerfis: [{value: []}] ,
    email: ['',[Validators.email,Validators.maxLength(50)]],
    funcao: [''],
    lider: [false, Validators.required],
  });

  constructor(
    public formBuilder: FormBuilder,
    public usuarioService: UsuarioService,
    public mensagemService: MensagemService,
    public perfilService: PerfilService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.carregarListas()
  }

  carregarListas(): void {
    LoadingConstant.loading = true;
    forkJoin([
        this.perfilService.listarTodos()
      ]
    ).subscribe({
      next: ([perfis]) => {
        this.perfis = perfis;
        this.activatedRoute.params.subscribe((params: any) => {
          if (params.id) {
            this.id = params.id;
            this.carregarDados(params.id);
          } else {
            LoadingConstant.loading = false
          }
        });
      }
    })
  }

  carregarDados(id: number) {
    LoadingConstant.loading = true;
    this.usuarioService.buscarPeloId(id).subscribe({
      next: (response) => {
        this.formulario.patchValue({
          nome: response.nome,
          idPerfis: response.perfis.map((perfil: any) => perfil.id),
          email: response.email,
          funcao: response.funcao,
          lider: response.lider
        });
        console.log(this.formulario);
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }

  enviarFormulario() {
    this.abrirModal = true;
  }

  confirmarFormulario() {
    this.abrirModal = false;
    LoadingConstant.loading = true;
    this.usuarioService.enviarFormulario(this.formulario.value, this.id).subscribe({
      next: () => {
        this.mensagemService.sucesso();
        this.finalizar();
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }

  finalizar() {
    LoadingConstant.loading = false;
    this.router.navigate(['/admin/cadastros/usuario'])
  }
}
