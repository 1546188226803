<app-titulo-pagina [titulo]="titulo" [contextoRota]="contextoRota" [icone]="icone" [textoAdicionar]="'nova solicitação'">
</app-titulo-pagina>
<div class="row">
  <div class="col-12 flex-column align-items-center tabela-lista">
    <app-tabela [itens]="lista" (visualizar)="abrirVisualizarDados($event.id)" [icone]="icone" [alternarCoresLinhas]="true"
                [contextoRota]="contextoRota" [paginado]="true" [outrasColunas]="outrasColunas" [exibirDescricao]="false" [exibirEditar]="false"
                [tamanho]="tamanho" [totalElementos]="totalItens" [textoEditar]="'revisar'" [textoVisualizar]="'acompanhar'"
                [marginBottomEditar]="true" (paginacao)="paginacao($event)" [pagina]="pagina">
    </app-tabela>
  </div>
  <app-modal-processo [arquivosSalvos]="arquivosSalvos" (volta)="abrirModal = false" [open]="abrirModal"
                     [solicitacao]="itemSelecionado"
  ></app-modal-processo>
</div>

