import {Component, OnInit} from '@angular/core';
import {MensagemService} from "../../_services/mensagem.service";
import {LoadingConstant} from "../../_model/loading-constant";
import {PaginatorDto} from "../../_componentes/tabela/tabela.component";
import {SolicitacaoPagamentoService} from "../../_services/web/solicitacao-pagamento.service";
import {AnexoDto} from "../cadastros/solicitacao-pagamento/model/anexo-dto";
import {Router} from "@angular/router";

@Component({
  selector: 'app-caixa-entrada',
  templateUrl: './caixa-entrada.component.html',
  styleUrls: ['./caixa-entrada.component.scss']
})
export class CaixaEntradaComponent implements OnInit {
  lista: any[] = [ ]
  icone = 'fa-envelope';
  contextoRota = 'caixa-entrada';
  titulo = 'Caixa de Entrada';
  pagina = 0;
  tamanho = 7;
  totalItens = 0;
  itemSelecionado: any;
  abrirModal = false;
  textoBotaoOk = '';
  textoBotaonaoOk = '';
  arquivosSalvos: AnexoDto[] = [];

  constructor(
    public solicitacaoPagamentoService: SolicitacaoPagamentoService,
    public mensagemService: MensagemService,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.listarTodos();
  }

  listarTodos(): void {
    LoadingConstant.loading = true;
    this.solicitacaoPagamentoService.listarPendentes()
      .subscribe({
      next: (response) => {
        let lista = [];
        lista.push(...response.map(solicitacao => (
          { ...solicitacao, titulo: 'Solicitação de pagamento', exibirInfo: true, tipo: 'SOLICITACAO_PAGAMENTO' }
        )));
        this.lista = lista;
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    });
  }


  abrirPopupDados(evento: any) {
    if (evento.revisao) {
      this.router.navigate(['admin/cadastros/solicitacao-pagamento/visualizar/', evento.id]);
    } else {
      this.itemSelecionado = evento;
      switch (evento.tipo) {
        default : {
          this.textoBotaonaoOk = 'Reprovar';
          this.textoBotaoOk = 'Aprovar';
        }
      }
      this.arquivosSalvos = evento.processo?.anexos;

      this.abrirModal = true;
    }
  }

  confirmarAprovacao(justificativa: any, aprovacao: boolean) {
    LoadingConstant.loading = true;
    this.abrirModal = false;
    this.solicitacaoPagamentoService.aprovar(this.itemSelecionado.id, aprovacao, justificativa).subscribe({
      next: () => {
        this.mensagemService.sucesso();
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
      this.listarTodos();
    })
  }
}
