import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {ListaAuxiliarDto} from "../../../../_model/lista-auxiliar-dto";
import {MensagemService} from "../../../../_services/mensagem.service";
import {ProjetoService} from "../../../../_services/web/projeto.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {LoadingConstant} from "../../../../_model/loading-constant";
import {forkJoin} from "rxjs";
import {LocalService} from "../../../../_services/web/local.service";
import {EventoService} from "../../../../_services/web/evento.service";
import {TipoEventoService} from "../../../../_services/web/tipo-evento.service";
import {ProjetoDto} from "../../projeto/model/projeto-dto";

@Component({
  selector: 'app-formulario-evento',
  templateUrl: './formulario-evento.component.html',
  styleUrls: ['./formulario-evento.component.scss']
})
export class FormularioEventoComponent implements OnInit {
  titulo = 'Eventos';
  contextoRota = 'evento';
  icone = 'fa-calendar';
  id = null;
  abrirModal = false;
  formulario = this.formBuilder.group({
    dataFim: [new Date()],
    dataInicio: [new Date()],
    idLocal: [0, [Validators.required, Validators.min(1)]],
    nome: ['', [Validators.required, Validators.maxLength(100)]],
    descricao: ['', [Validators.maxLength(100)]],
    idProjeto: [0, [Validators.required, Validators.min(1)]],
    cargaHoraria: [0, [Validators.required]],
    idTipoEvento: [0, [Validators.required, Validators.min(1)]]
  });
  locais: ListaAuxiliarDto[] = [];
  projetos: ProjetoDto[] = [];
  tiposEvento: ListaAuxiliarDto[] = [];
  constructor(
    public formBuilder: FormBuilder,
    public mensagemService: MensagemService,
    public eventoService: EventoService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public localService: LocalService,
    public projetoService: ProjetoService,
    public tipoEventoService: TipoEventoService,
    public datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.carregarListas()
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.idLocal) {
        this.formulario.patchValue({
          idLocal: params.idLocal
        })
      }
      if (params.id) {
        this.id = params.id;
        this.carregarDados(params.id);
      }
    });
  }

  carregarDados(id: number) {
    LoadingConstant.loading = true;
    this.eventoService.buscarPeloId(id).subscribe({
      next: (response) => {
        this.formulario.patchValue(response);
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }

  enviarFormulario() {
    this.abrirModal = true;
  }

  confirmarFormulario() {
    this.abrirModal = false;
    LoadingConstant.loading = true;
    this.eventoService.enviarFormulario(this.formulario.value, this.id).subscribe({
      next: () => {
        this.mensagemService.sucesso();
        this.finalizar();
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }

  finalizar() {
    LoadingConstant.loading = false;
    this.router.navigate(['/admin/cadastros/evento'])
  }

  carregarListas(): void {
    LoadingConstant.loading = true;
    forkJoin(
      [
        this.localService.listarLocais(),
        this.projetoService.listarTodos(),
        this.tipoEventoService.listarTipoEvento()
      ]
    ).subscribe({
      next: ([locais, projetos, tipos]) => {
        this.locais = locais;
        this.projetos = projetos;
        this.tiposEvento = tipos;
      }
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }

  abrirLocal() {
    if (this.id) {
      this.router.navigate(['/admin/cadastros/local/novo/evento/', true, this.id]);
    } else {
      this.router.navigate(['/admin/cadastros/local/novo/evento/', true]);
    }
  }
}
