<app-titulo-pagina [titulo]="titulo" [contextoRota]="contextoRota" [icone]="icone" (cancelar)="finalizar()" tipoPagina="cadastro">
</app-titulo-pagina>
<div class="row pb-100">
  <div class="col-12 col-md-7 pi-0" id="formulario-topo">
    <h2 class="color-primario subtitulo">Rúbricas de Projeto</h2>
    <p class="color-texto">Preencha as informações abaixo para adicionar uma nova Rúbrica no projeto</p>

    <h3 class="color-primario">Informações Gerais</h3>
    <div class="container-formulario">
      <form [formGroup]="formulario">

        <div class="row">
          <div class="form-group col-12 col-md-4">
            <label class="form-control-label" for="codigo">Código da Rubrica *</label>
            <input type="text" id="codigo" class="form-control" placeholder="Código" formControlName="codigo" />
          </div>
          <div class="form-group col-12 col-md-4">
            <label class="form-control-label" for="descricao">Descrição da Rubrica *</label>
            <input type="text" id="descricao" class="form-control" placeholder="Descrição" formControlName="descricao" />
          </div>
          <div class="form-group col-12 col-md-4">
            <label class="form-control-label" for="idRubrica">Rubrica Institucional *</label>
            <ng-select [closeOnSelect]="true" id="idRubrica" [items]="rubricas" bindLabel="descricao" bindValue="id"
                       appendTo="body" formControlName="idRubrica" placeholder="Selecione">
            </ng-select>
          </div>
          <div class="form-group col-12 col-md-4">
            <label class="form-control-label" for="valorMensal">Valor Mensal Unit. *</label>
            <input type="text" id="valorMensal" mask="separator.2" thousandSeparator="." decimalMarker=","
                   prefix="R$ " class="form-control" formControlName="valorMensal" />
          </div>
          <div class="form-group col-12 col-md-4">
            <label class="form-control-label" for="meses">Meses Quant. *</label>
            <input type="number" id="meses" class="form-control" formControlName="meses" />
          </div>
          <div class="form-group col-12 col-md-4">
            <label class="form-control-label" for="valorTotal">Valor Total</label>
            <input type="text" id="valorTotal" mask="separator.2" thousandSeparator="." decimalMarker=","
                   prefix="R$ " class="form-control" readonly formControlName="valorTotal" />
          </div>
        </div>
      </form>
    </div>
  </div>
    <div class="col-12 col-md-7 pi-0 flex justify-content-beetwen align-items-center">
      <h5 class="color-texto mb-0 color-primario">* Campos obrigatórios</h5>
      <div>
        <button class="bg-secondary color-black mr-3" (click)="limparFormulario()">
          cancelar
        </button>
        <button class="bg-primario color-white" (click)="enviarFormulario()" [disabled]="formulario.invalid">
          adicionar
        </button>
      </div>
    </div>
  <div class="col-12 col-md-7 pi-0 pt-5" *ngIf="rubricasProjetos.length > 0">
  <h3 class="color-primario" >Rubricas do Projeto</h3>
    <div class="col-12 pi-0 flex justify-content-end align-items-center">

    <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
      <button type="button" class="bg-white color-primario pr-1 pl-1" (click)="alterarOrdem()">
        <i class="fas fa-sort-amount-down color-primario" *ngIf="ordem == 'desc'"></i>
        <i class="fas fa-sort-amount-up color-primario" *ngIf="ordem == 'asc'"></i>
      </button>
      <button type="button" class="bg-white color-primario dropdown-toggle-split pi-0" id="dropdownOrder" ngbDropdownToggle>{{ordenacao.label}}</button>
      <div class="dropdown-menu" ngbDropdownMenu>
        <button ngbDropdownItem *ngFor="let item of ordenacoes" (click)="alterarCampoOrdenacao(item)">{{item.label}}</button>
      </div>
    </div>
    </div>
    <div class="container-formulario">

      <app-tabela [itens]="rubricasProjetos" [exibirVisualizar]="false" [contextoRota]="contextoRota" [acaoEditarPadrao]="false"
                  [exibirUltimosAdicionados]="false" [exibirTextoEditar]="false" [exibirRemover]="true" [idSelecionado]="idRubricaProjeto"
                  [tamanho]="tamanho" [totalElementos]="totalItens" (paginacao)="paginacao($event)" [pagina]="pagina"
                  [paginado]="true" (remover)="deletarRubrica($event)" (editar)="editarRubrica($event)">
      </app-tabela>
    </div>
  </div>

  <div class="col-12 col-md-7 pi-0 flex justify-content-end align-items-center" style="margin-top: 35px">
    <button class="bg-secondary color-black" (click)="finalizar()">
      voltar
    </button>
  </div>
  <app-modal-confirmacao (ok)="confirmarOperacao($event.informacao)" [informacao]="operacao"
                         (naoOk)="cancelar()" (volta)="cancelar()" [open]="abrirModal"></app-modal-confirmacao>

</div>
