import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {MensagemService} from "../../../../_services/mensagem.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LoadingConstant} from "../../../../_model/loading-constant";
import {PublicoPreferencialService} from "../../../../_services/web/publico-preferencial.service";

@Component({
  selector: 'app-formulario-publico-preferencial',
  templateUrl: './formulario-publico-preferencial.component.html',
  styleUrls: ['./formulario-publico-preferencial.component.scss']
})
export class FormularioPublicoPreferencialComponent implements OnInit {

  titulo = 'Público Preferencial';
  contextoRota = 'publico-preferencial';
  icone = 'fa-info-circle';
  id = null;
  abrirModal = false;
  formulario = this.formBuilder.group({
    descricao: ['', [Validators.required, Validators.maxLength(100)]]
  });

  constructor(
    public formBuilder: FormBuilder,
    public publicoPreferencialService: PublicoPreferencialService,
    public mensagemService: MensagemService,
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.id) {
        this.id = params.id;
        this.carregarDados(params.id);
      }
    });
  }

  carregarDados(id: number) {
    LoadingConstant.loading = true;
    this.publicoPreferencialService.buscarPeloId(id).subscribe({
      next: (response) => {
        this.formulario.patchValue({
          descricao: response.descricao
        });
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }

  enviarFormulario() {
    this.abrirModal = true;
  }

  confirmarFormulario() {
    this.abrirModal = false;
    LoadingConstant.loading = true;
    this.publicoPreferencialService.enviarFormulario(this.formulario.value, this.id).subscribe({
      next: () => {
        this.mensagemService.sucesso();
        this.finalizar();
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }

  finalizar() {
    LoadingConstant.loading = false;
    this.router.navigate(['/admin/cadastros/publico-preferencial'])
  }
}
