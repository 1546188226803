import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {ProjetoDto} from "../../projeto/model/projeto-dto";
import {MensagemService} from "../../../../_services/mensagem.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LoadingConstant} from "../../../../_model/loading-constant";
import {forkJoin} from "rxjs";
import {RubricaCategoriaDto} from "../../rubrica-categoria/_model/rubrica-categoria-dto";
import {RubricaCategoriaService} from "../../../../_services/web/rubrica-categoria.service";
import {RubricaService} from "../../../../_services/web/rubrica.service";

@Component({
  selector: 'app-formulario-rubrica',
  templateUrl: './formulario-rubrica.component.html',
  styleUrls: ['./formulario-rubrica.component.scss']
})
export class FormularioRubricaComponent implements OnInit {
  titulo = 'Rúbricas';
  contextoRota = 'rubrica';
  icone = 'fa-signature';
  id = null;
  abrirModal = false;
  formulario = this.formBuilder.group({
    idCategoria: [0, [Validators.required, Validators.min(1)]],
    codigo: ['', [Validators.required, Validators.maxLength(20)]],
    descricao: ['', [Validators.maxLength(60)]],
    tipo: ['', [Validators.required, Validators.max(1)]]
  });
  categorias: RubricaCategoriaDto[] = [];
  tipos: {id: string, descricao: string}[] = [{id: 'A', descricao: 'A'}, {id: 'T', descricao: 'T'}];
  constructor(
    public formBuilder: FormBuilder,
    public mensagemService: MensagemService,
    public rubricaService: RubricaService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public categoriasRubricasService: RubricaCategoriaService,
  ) { }

  ngOnInit(): void {
    this.carregarListas()
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.idCategoriaRubrica) {
        this.formulario.patchValue({
          idCategoria: params.idCategoriaRubrica
        })
      }
      if (params.id) {
        this.id = params.id;
        this.carregarDados(params.id);
      }
    });
  }

  carregarDados(id: number) {
    LoadingConstant.loading = true;
    this.rubricaService.buscarPeloId(id).subscribe({
      next: (response) => {
        this.formulario.patchValue(response);
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }

  enviarFormulario() {
    this.abrirModal = true;
  }

  confirmarFormulario() {
    this.abrirModal = false;
    LoadingConstant.loading = true;
    this.rubricaService.enviarFormulario(this.formulario.value, this.id).subscribe({
      next: () => {
        this.mensagemService.sucesso();
        this.finalizar();
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }

  finalizar() {
    LoadingConstant.loading = false;
    this.router.navigate(['/admin/cadastros/rubrica'])
  }

  carregarListas(): void {
    LoadingConstant.loading = true;
    forkJoin(
      [
        this.categoriasRubricasService.listarRubricaCategoria(),
      ]
    ).subscribe({
      next: ([categorias]) => {
        this.categorias = categorias
      }
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }

  abrirLocal() {
    if (this.id) {
      this.router.navigate(['/admin/cadastros/rubrica-categoria/novo/rubrica/', true, this.id]);
    } else {
      this.router.navigate(['/admin/cadastros/rubrica-categoria/novo/rubrica/', true]);
    }
  }
}
