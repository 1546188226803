import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {NavigationStart, Router} from "@angular/router";
import {Alert, AlertType} from "./model/alerta-dto";
import {MensagemService} from "../../_services/mensagem.service";

@Component({
  selector: 'app-alerta',
  templateUrl: './alerta.component.html',
  styleUrls: ['./alerta.component.scss']
})
export class AlertaComponent implements OnInit, OnDestroy {
  @Input() id = 'default-alert';
  @Input() fade = true;

  alerts: Alert[] = [];
  alertSubscription: Subscription | undefined;
  routeSubscription: Subscription | undefined;

  constructor(private router: Router, private alertService: MensagemService) { }

  ngOnInit() {
    this.alertSubscription = this.alertService.onAlert(this.id)
      .subscribe({
        next: (alert: Alert) => {
          if (!alert.message) {
            this.alerts = this.alerts.filter(x => x.keepAfterRouteChange);

            this.alerts.forEach(x => delete x.keepAfterRouteChange);
            return;
          }

          this.alerts.push(alert);

          if (alert.autoClose) {
            setTimeout(() => this.removeAlert(alert), 6000);
          }
        }
        });

    this.routeSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.alertService.clear(this.id);
      }
    });
  }

  ngOnDestroy() {
    if (this.alertSubscription) {
      this.alertSubscription.unsubscribe();
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  removeAlert(alert: Alert) {
    if (!this.alerts.includes(alert)) return;

    if (this.fade) {
        let alerta = this.alerts.find(x => x === alert);
        if (alerta) {
          alerta.fade = true;

          setTimeout(() => {
            this.alerts = this.alerts.filter(x => x !== alerta);
          }, 400);
      }
    } else {
      this.alerts = this.alerts.filter(x => x !== alert);
    }
  }

  cssClass(alert: Alert) {
    if (!alert) return;

    const classes = ['alert', 'alert-dismissable'];

    const alertTypeClass = {
      [AlertType.Success]: 'alert alert-success',
      [AlertType.Error]: 'alert alert-danger',
      [AlertType.Info]: 'alert alert-info',
      [AlertType.Warning]: 'alert alert-warning'
    }

    classes.push(alertTypeClass[alert.type]);

    if (alert.fade) {
      classes.push('fade');
    }

    return classes.join(' ');
  }
}
