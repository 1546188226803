<app-titulo-pagina [titulo]="titulo" [contextoRota]="contextoRota" [icone]="icone">
</app-titulo-pagina>
<div class="row">
  <div class="col-12 col-md-8 flex-column align-items-center tabela-lista">
    <app-tabela [itens]="lista" (visualizar)="abrirPopupDados($event.id)" [icone]="icone" [exibirVisualizar]="false"
                [contextoRota]="contextoRota" [paginado]="true"
                [tamanho]="tamanho" [totalElementos]="totalItens" (paginacao)="paginacao($event)" [pagina]="pagina">
    </app-tabela>
  </div>
</div>
