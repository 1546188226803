<app-titulo-pagina [titulo]="titulo" [contextoRota]="contextoRota" [icone]="icone" (cancelar)="finalizar()" tipoPagina="cadastro">
</app-titulo-pagina>
<div class="row pb-100">
  <div class="col-12 col-md-7 pi-0">
    <h2 class="color-primario subtitulo">{{ this.id ? 'Editar' : 'Adicionar Novo'}}</h2>
    <p class="color-texto">Preencha as informações abaixo para adicionar um novo Evento</p>

    <h3 class="color-primario">Informações gerais</h3>
    <div class="container-formulario">
      <form [formGroup]="formulario">
        <div class="row">
          <div class="form-group col-12">
            <label class="form-control-label" for="codigo">Código *</label>
            <input type="text" id="codigo" class="form-control" placeholder="Código" formControlName="codigo" maxlength="20" />
          </div>
          <div class="form-group col-12">
            <label class="form-control-label" for="descricao">Descrição</label>
            <textarea rows="4" class="form-control form-control-alternative" id="descricao" placeholder="Descrição do evento" formControlName="descricao">
            </textarea>
          </div>
          <div class="form-group col-12">
            <label class="form-control-label flex justify-content-between" for="categoria">Categoria *
              <button class="bg-primario color-white" (click)="abrirLocal()">
                nova categoria
              </button>
            </label>
            <select class="form-control" id="categoria" formControlName="idCategoria">
              <option *ngFor="let categoria of categorias" [value]="categoria.id">{{categoria.descricao}}</option>
            </select>
          </div>
          <div class="form-group col-12">
            <label class="form-control-label" for="tipo">Tipo *</label>
            <select class="form-control" id="tipo" formControlName="tipo">
              <option *ngFor="let tipo of tipos" [value]="tipo.id">{{tipo.descricao}}</option>
            </select>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="col-12 col-md-7 pi-0 flex justify-content-beetwen align-items-center">
    <h5 class="color-texto mb-0 color-primario">* Campos obrigatórios</h5>
    <button class="bg-primario color-white" (click)="enviarFormulario()" [disabled]="formulario.invalid">
      adicionar
    </button>
  </div>
  <app-modal-confirmacao (ok)="confirmarFormulario()" (naoOk)="abrirModal = false" (volta)="abrirModal = false" [open]="abrirModal"></app-modal-confirmacao>
</div>
