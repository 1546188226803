import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {ComponentesModule} from "./_componentes/componentes.module";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {HashLocationStrategy, LocationStrategy} from "@angular/common";
import { LoginComponent } from './login/login.component';
import {ReactiveFormsModule} from "@angular/forms";
import {AdminModule} from "./admin/admin.module";
import {HttpInterceptorService} from "./core/http/http-interceptor.service";


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ComponentesModule,
    HttpClientModule,
    ReactiveFormsModule,
    AdminModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    [{
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
      HttpClientModule
    }]
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
