<div class="card-footer py-4">
  <nav aria-label="...">
    <ul class="pagination justify-content-end mb-0">
      <li class="page-item" [class.disabled]="pagina == 0">
        <a class="page-link" (click)="reduzirPagina(pagina - 1)">
          <i class="fas fa-angle-left"></i>
          <span class="sr-only">Anterior</span>
        </a>
      </li>
      <li class="page-item" *ngFor="let paginaLista of listaPaginas()" (click)="paginar(paginaLista - 1)" [class.active]="paginaLista - 1 == pagina">
        <a class="page-link">{{paginaLista}}</a>
      </li>
      <li class="page-item" [class.disabled]="pagina == totalPaginas.length - 1 || this.totalElementosHtml == 0">
        <a class="page-link" (click)="aumentarPagina(pagina + 1)" >
          <i class="fas fa-angle-right"></i>
          <span class="sr-only">Próxima</span>
        </a>
      </li>
    </ul>
  </nav>
</div>
