import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {PublicoPreferencialService} from "../../../../_services/web/publico-preferencial.service";
import {ListaAuxiliarDto} from "../../../../_model/lista-auxiliar-dto";
import {MensagemService} from "../../../../_services/mensagem.service";
import {LoadingConstant} from "../../../../_model/loading-constant";
import {PaginatorDto} from "../../../../_componentes/tabela/tabela.component";


@Component({
  selector: 'app-lista-publico-preferencial',
  templateUrl: './lista-publico-preferencial.component.html',
  styleUrls: ['./lista-publico-preferencial.component.scss']
})
export class ListaPublicoPreferencialComponent implements OnInit {

  listaPublicoPreferencial: ListaAuxiliarDto[] = [];
  icone = 'fa-info-circle';
  contextoRota = 'publico-preferencial';
  titulo = 'Público Preferencial';
  pagina = 0;
  tamanho = 7;
  totalItens = 0;
  constructor(
    public router: Router,
    public publicoPreferencialService: PublicoPreferencialService,
    public mensagemService: MensagemService
  ) { }

  ngOnInit(): void {
    this.listarPublicoPreferencial();
  }

  listarPublicoPreferencial(): void {
    LoadingConstant.loading = true;
    this.publicoPreferencialService.listarPublicoPreferencialPaginado(this.pagina, this.tamanho).subscribe({
      next: (response) => {
        this.listaPublicoPreferencial = response.content;
        this.totalItens = response.totalElements;
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    });
  }

  abrirPopupDados(id: number) {

  }

  paginacao(evento: PaginatorDto): void {
    this.pagina = evento.pagina;
    this.tamanho = evento.tamanho;
    this.listarPublicoPreferencial();
  }

}
