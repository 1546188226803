import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ListaLocalComponent} from "./cadastros/local/lista-local/lista-local.component";
import {MenuInicialComponent} from "./cadastros/menu-inicial/menu-inicial.component";
import {
  ListaTipoParticipacaoComponent
} from "./cadastros/tipo-participacao/lista-tipo-participacao/lista-tipo-participacao.component";
import {
  FormularioTipoParticipacaoComponent
} from "./cadastros/tipo-participacao/formulario-tipo-participacao/formulario-tipo-participacao.component";
import {FormularioLocalComponent} from "./cadastros/local/formulario-local/formulario-local.component";
import {
  ListaPublicoPreferencialComponent
} from "./cadastros/publico-preferencial/lista-publico-preferencial/lista-publico-preferencial.component";
import {
  FormularioPublicoPreferencialComponent
} from "./cadastros/publico-preferencial/formulario-publico-preferencial/formulario-publico-preferencial.component";
import {
  ListaObjetivoReferenciadoComponent
} from "./cadastros/objetivo-referenciado/lista-objetivo-referenciado/lista-objetivo-referenciado.component";
import {
  FormularioObjetivoReferenciadoComponent
} from "./cadastros/objetivo-referenciado/formulario-objetivo-referenciado/formulario-objetivo-referenciado.component";
import {ListaTipoEventoComponent} from "./cadastros/tipo-evento/lista-tipo-evento/lista-tipo-evento.component";
import {
  FormularioTipoEventoComponent
} from "./cadastros/tipo-evento/formulario-tipo-evento/formulario-tipo-evento.component";
import {ListaEmpresaComponent} from "./cadastros/empresa/lista-empresa/lista-empresa.component";
import {FormularioEmpresaComponent} from "./cadastros/empresa/formulario-empresa/formulario-empresa.component";
import {ListaUsuarioComponent} from "./cadastros/usuario/lista-usuario/lista-usuario.component";
import {FormularioUsuarioComponent} from "./cadastros/usuario/formulario-usuario/formulario-usuario.component";
import {FormularioNovaSenhaComponent} from "./cadastros/usuario/formulario-nova-senha/formulario-nova-senha.component";
import {ListaProjetoComponent} from "./cadastros/projeto/lista-projeto/lista-projeto.component";
import {FormularioProjetoComponent} from "./cadastros/projeto/formulario-projeto/formulario-projeto.component";
import {ListaStakeholderComponent} from "./cadastros/stakeholder/lista-stakeholder/lista-stakeholder.component";
import {
  FormularioStakeholderComponent
} from "./cadastros/stakeholder/formulario-stakeholder/formulario-stakeholder.component";
import {ListaEventoComponent} from "./cadastros/evento/lista-evento/lista-evento.component";
import {FormularioEventoComponent} from "./cadastros/evento/formulario-evento/formulario-evento.component";
import {VisualizacaoEventoComponent} from "./cadastros/evento/visualizacao-evento/visualizacao-evento.component";
import {ListaMunicipioComponent} from './cadastros/municipio/lista-municipio/lista-municipio.component';
import {FormularioMunicipioComponent} from './cadastros/municipio/formulario-municipio/formulario-municipio.component';
import {ListaRubricaComponent} from "./cadastros/rubrica/lista-rubrica/lista-rubrica.component";
import {FormularioRubricaComponent} from "./cadastros/rubrica/formulario-rubrica/formulario-rubrica.component";
import {
  FormularioRubricaCategoriaComponent
} from "./cadastros/rubrica-categoria/formulario-rubrica-categoria/formulario-rubrica-categoria.component";
import {
  ListaRubricaCategoriaComponent
} from "./cadastros/rubrica-categoria/lista-rubrica-categoria/lista-rubrica-categoria.component";
import {GerenciamentoComponent} from "./cadastros/gerenciamento/gerenciamento.component";
import {
  ListaRubricaProjetoComponent
} from "./cadastros/rubrica-projeto/lista-rubrica-projeto/lista-rubrica-projeto.component";
import {
  FormularioRubricaProjetoComponent
} from "./cadastros/rubrica-projeto/formulario-rubrica-projeto/formulario-rubrica-projeto.component";
import {
  FormularioSolicitacaoPagamentoComponent
} from "./cadastros/solicitacao-pagamento/formulario-solicitacao-pagamento/formulario-solicitacao-pagamento.component";
import {
  ListaSolicitacaoPagamentoComponent
} from "./cadastros/solicitacao-pagamento/lista-solicitacao-pagamento/lista-solicitacao-pagamento.component";
import {
  FormularioPacoteProjetoComponent
} from "./cadastros/pacote-projeto/formulario-pacote-projeto/formulario-pacote-projeto.component";
import {
  ListaPacoteProjetoComponent
} from "./cadastros/pacote-projeto/lista-pacote-projeto/lista-pacote-projeto.component";
import {CaixaEntradaComponent} from "./caixa-entrada/caixa-entrada.component";
import {
  VisualizacaoSolicitacaoPagamentoComponent
} from "./cadastros/solicitacao-pagamento/visualizacao-solicitacao-pagamento/visualizacao-solicitacao-pagamento.component";

const routes: Routes = [
  { path: '', redirectTo: 'cadastros/menu', pathMatch: 'full'},
  { path: 'caixa-entrada', component: CaixaEntradaComponent},
  {
    path: 'cadastros', children: [
      {path: 'menu', component: MenuInicialComponent},
      {path: 'gerenciamento', component: GerenciamentoComponent},
      {path: 'local', component: ListaLocalComponent},
      {path: 'local/novo', component: FormularioLocalComponent},
      {path: 'local/novo/evento/:isEvento', component: FormularioLocalComponent},
      {path: 'local/novo/evento/:isEvento/:idEvento', component: FormularioLocalComponent},
      {path: 'local/editar/:id', component: FormularioLocalComponent},
      {path: 'tipo-participacao', component: ListaTipoParticipacaoComponent},
      {path: 'tipo-participacao/novo', component: FormularioTipoParticipacaoComponent},
      {path: 'tipo-participacao/editar/:id', component: FormularioTipoParticipacaoComponent},
      {path: 'tipo-evento', component: ListaTipoEventoComponent},
      {path: 'tipo-evento/novo', component: FormularioTipoEventoComponent},
      {path: 'tipo-evento/editar/:id', component: FormularioTipoEventoComponent},
      {path: 'publico-preferencial', component: ListaPublicoPreferencialComponent},
      {path: 'publico-preferencial/novo', component: FormularioPublicoPreferencialComponent},
      {path: 'publico-preferencial/editar/:id', component: FormularioPublicoPreferencialComponent},
      {path: 'objetivo-referenciado', component: ListaObjetivoReferenciadoComponent},
      {path: 'objetivo-referenciado/novo', component: FormularioObjetivoReferenciadoComponent},
      {path: 'objetivo-referenciado/editar/:id', component: FormularioObjetivoReferenciadoComponent},
      {path: 'financiador', component: ListaEmpresaComponent},
      {path: 'financiador/novo', component: FormularioEmpresaComponent},
      {path: 'financiador/editar/:id', component: FormularioEmpresaComponent},
      {path: 'usuario', component: ListaUsuarioComponent},
      {path: 'usuario/novo', component: FormularioUsuarioComponent},
      {path: 'usuario/editar/:id', component: FormularioUsuarioComponent},
      {path: 'usuario/novasenha', component: FormularioNovaSenhaComponent},
      {path: 'projeto', component: ListaProjetoComponent},
      {path: 'projeto/novo', component: FormularioProjetoComponent},
      {path: 'projeto/editar/:id', component: FormularioProjetoComponent},
      {path: 'stakeholder', component: ListaStakeholderComponent},
      {path: 'stakeholder/novo', component: FormularioStakeholderComponent},
      {path: 'stakeholder/novo/:idEvento', component: FormularioStakeholderComponent},
      {path: 'stakeholder/editar/:id', component: FormularioStakeholderComponent},
      {path: 'evento', component: ListaEventoComponent},
      {path: 'evento/novo', component: FormularioEventoComponent},
      {path: 'evento/novo/local/:idLocal', component: FormularioEventoComponent},
      {path: 'evento/editar/local/:idLocal/:id', component: FormularioEventoComponent},
      {path: 'evento/editar/:id', component: FormularioEventoComponent},
      {path: 'evento/visualizar/:id', component: VisualizacaoEventoComponent},
      {path: 'municipio', component: ListaMunicipioComponent},
      {path: 'municipio/novo', component: FormularioMunicipioComponent},
      {path: 'municipio/editar/:id', component: FormularioMunicipioComponent},
      {path: 'rubrica', component: ListaRubricaComponent},
      {path: 'rubrica/novo', component: FormularioRubricaComponent},
      {path: 'rubrica/novo/rubrica-categoria/:idCategoriaRubrica', component: FormularioRubricaComponent},
      {path: 'rubrica/editar/rubrica-categoria/:idCategoriaRubrica/:id', component: FormularioRubricaComponent},
      {path: 'rubrica/editar/:id', component: FormularioRubricaComponent},
      {path: 'rubrica-categoria', component: ListaRubricaCategoriaComponent},
      {path: 'rubrica-categoria/novo', component: FormularioRubricaCategoriaComponent},
      {path: 'rubrica-categoria/novo/rubrica/:isRubrica', component: FormularioRubricaCategoriaComponent},
      {path: 'rubrica-categoria/novo/rubrica/:isRubrica/:idRubrica', component: FormularioRubricaCategoriaComponent},
      {path: 'rubrica-categoria/editar/:id', component: FormularioRubricaCategoriaComponent},
      {path: 'rubrica-projeto/projeto/:id', component: FormularioRubricaCategoriaComponent},
      {path: 'rubrica-projeto', component: ListaRubricaProjetoComponent},
      {path: 'rubrica-projeto/editar/:id', component: FormularioRubricaProjetoComponent},
      {path: 'solicitacao-pagamento/novo', component: FormularioSolicitacaoPagamentoComponent},
      {path: 'solicitacao-pagamento', component: ListaSolicitacaoPagamentoComponent},
      {path: 'solicitacao-pagamento/editar/:id', component: FormularioSolicitacaoPagamentoComponent},
      {path: 'solicitacao-pagamento/visualizar/:id', component: VisualizacaoSolicitacaoPagamentoComponent},
      {path: 'pacote-projeto/novo', component: FormularioPacoteProjetoComponent},
      {path: 'pacote-projeto', component: ListaPacoteProjetoComponent},
      {path: 'pacote-projeto/editar/:id', component: FormularioPacoteProjetoComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
