<app-titulo-pagina [titulo]="titulo" [contextoRota]="contextoRota" [icone]="icone" (cancelar)="finalizar()" tipoPagina="cadastro">
</app-titulo-pagina>
<div class="row pb-100">
  <div class="col-12 col-md-7 pi-0">
    <h2 class="color-primario subtitulo">{{ this.id ? 'Editar' : 'Adicionar Novo'}}</h2>
    <p class="color-texto">Preencha as informações abaixo para adicionar um novo Stakeholder</p>

    <form [formGroup]="formulario">
      <h3 class="color-primario">Informações gerais</h3>
      <div class="container-formulario row" id="informacoes-gerais">
        <div class="form-group col-12 col-md-6">
          <label class="form-control-label" for="nome">Nome Completo *</label>
          <input type="text" id="nome" class="form-control" placeholder="Nome Completo" formControlName="nome" />
        </div>
        <div class="form-group col-12 col-md-6">
          <label class="form-control-label" for="nomeMae">Nome da mãe *</label>
          <input type="text" id="nomeMae" class="form-control" placeholder="Informe o nome da sua Mãe" formControlName="nomeMae" />
        </div>
        <div class="form-group col-12 col-md-6">
          <label class="form-control-label" for="idLocalNascimento">Local de Nascimento *</label>
          <select class="form-control" id="idLocalNascimento" formControlName="idLocalNascimento">
            <option *ngFor="let municipio of municipios" [value]=" municipio.id">{{municipio.nome}}</option>
          </select>
        </div>
        <div class="form-group col-12 col-md-6">
          <label class="form-control-label" for="cpf">Cpf</label>
          <input type="text" mask="CPF_CNPJ" id="cpf" class="form-control" placeholder="000.000.000-00" formControlName="cpf" />
        </div>
        <div class="form-group col-12 col-md-6">
          <label class="form-control-label" for="dataNascimento">Data Nascimento</label>
          <input type="date" id="dataNascimento" class="form-control" placeholder="Data de Nascimento" formControlName="dataNascimento" />
        </div>
        <div class="form-group col-12 col-md-6">
          <label class="form-control-label" for="genero">Gênero</label>
          <select class="form-control" id="genero" formControlName="genero">
            <option [value]="genero.value" *ngFor="let genero of generos">{{genero.genero}}</option>
          </select>
        </div>

        <div class="form-group col-12">
          <label class="form-control-label" for="observacao">Observação</label>
          <textarea rows="4" class="form-control form-control-alternative" id="observacao" placeholder="Observação" formControlName="observacao">
          </textarea>
        </div>
      </div>
      <h3 class="color-primario">Contatos</h3>
      <div class="container-formulario row" id="contatos">
        <div class="form-group col-12 col-md-6">
          <label class="form-control-label" for="email">Email</label>
          <input type="text" id="email" class="form-control" placeholder="Informe o seu Email" formControlName="email" />
        </div>
        <div class="form-group col-12 col-md-6">
          <label class="form-control-label" for="telefone">Telefone</label>
          <input [validation]="false" type="text" mask="(00) 00000-0000" id="telefone" class="form-control" placeholder="(00) 00000-0000" formControlName="telefone" />
        </div>

      </div>


    </form>
  </div>
  <div class="col-12 col-md-7 pi-0 flex justify-content-beetwen align-items-center">
    <h5 class="color-texto mb-0 color-primario">* Campos obrigatórios</h5>
    <button class="bg-primario color-white" (click)="enviarFormulario()" [disabled]="formulario.invalid">
      adicionar
    </button>
  </div>
  <app-modal-confirmacao (ok)="confirmarFormulario()" (naoOk)="abrirModal = false" (volta)="abrirModal = false" [open]="abrirModal"></app-modal-confirmacao>
</div>
