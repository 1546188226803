import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {MensagemService} from "../../../../_services/mensagem.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LoadingConstant} from "../../../../_model/loading-constant";
import {ProjetoService} from "../../../../_services/web/projeto.service";
import {ObjetivoReferenciadoDto} from "../../objetivo-referenciado/_model/objetivo-referenciado-dto";
import {ObjetivoReferenciadoService} from "../../../../_services/web/objetivo-referenciado.service";
import {EmpresaService} from "../../../../_services/web/empresa.service";
import {PublicoPreferencialService} from "../../../../_services/web/publico-preferencial.service";
import {forkJoin} from "rxjs";
import {EmpresaDto} from "../../empresa/_model/empresa-dto";
import {ListaAuxiliarDto} from "../../../../_model/lista-auxiliar-dto";
import {RubricaService} from "../../../../_services/web/rubrica.service";
import {RubricaDto} from "../../rubrica/_model/rubrica-dto";
import {ProjetoDto} from "../model/projeto-dto";
import {UsuarioService} from "../../../../_services/web/usuario.service";
import {UsuarioDto} from "../../usuario/model/usuario-dto";

@Component({
  selector: 'app-formulario-projeto',
  templateUrl: './formulario-projeto.component.html',
  styleUrls: ['./formulario-projeto.component.scss']
})
export class FormularioProjetoComponent implements OnInit {
  titulo = 'Projetos';
  contextoRota = 'projeto';
  icone = 'fa-project-diagram';
  id = null;
  abrirModal = false;
  formulario = this.formBuilder.group({
    dataFim: [new Date()],
    dataInicio: [new Date()],
    idFinanciador: [ [], [Validators.required]],
    nome: ['', [Validators.required, Validators.maxLength(100)]],
    nomeFantasia: ['', [Validators.maxLength(100)]],
    objetivo: ['', [Validators.maxLength(500)]],
    idObjetivoReferenciado: [null, [Validators.required]],
    orcamento: [0, [Validators.required]],
    idPublicoPreferencial: [null, [Validators.required]],
    idUsuarioOrdenador: [null, [Validators.required]],
    idUsuarioResponsavel: [null, [Validators.required]],
    rubricas: undefined
  });
  objetivosReferenciados: ObjetivoReferenciadoDto[] = [];
  empresas: EmpresaDto[] = [];
  publicosPreferenciais: ListaAuxiliarDto[] = [];
  rubricas: RubricaDto[] = [];
  usuarios: UsuarioDto[] = [];
  constructor(
    public formBuilder: FormBuilder,
    public mensagemService: MensagemService,
    public projetoService: ProjetoService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public objetivoReferenciadoService: ObjetivoReferenciadoService,
    public empresaService: EmpresaService,
    public publicoPreferencialService: PublicoPreferencialService,
    public rubricaService: RubricaService,
    public usuarioService: UsuarioService,
  ) {

  }

  ngOnInit(): void {
    this.carregarListas()
  }

  carregarDados(id: number) {
    LoadingConstant.loading = true;
    this.projetoService.buscarPeloId(id).subscribe({
      next: (response: ProjetoDto) => {
        this.formulario.patchValue(response);
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }

  enviarFormulario() {
    this.abrirModal = true;
  }

  confirmarFormulario() {
    this.abrirModal = false;
    LoadingConstant.loading = true;
    this.projetoService.enviarFormulario(this.formulario.value, this.id).subscribe({
      next: () => {
        this.mensagemService.sucesso();
        this.finalizar();
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }

  finalizar() {
    LoadingConstant.loading = false;
    this.router.navigate(['/admin/cadastros/projeto'])
  }

  carregarListas(): void {
    LoadingConstant.loading = true;
    forkJoin(
      [this.objetivoReferenciadoService.listarObjetivoReferenciado(),
      this.empresaService.listarEmpresa(),
      this.publicoPreferencialService.listarPublicoPreferencial(),
        this.rubricaService.listarRubrica(),
        this.usuarioService.listarTodos()
      ]
    ).subscribe({
      next: ([objetivos, empresas, publicos, rubricas, usuarios]) => {
        this.objetivosReferenciados = objetivos;
        this.empresas = empresas;
        this.publicosPreferenciais = publicos;
        this.rubricas = rubricas;
        this.usuarios = usuarios
        this.activatedRoute.params.subscribe((params: any) => {
          if (params.id) {
            this.id = params.id;
            this.carregarDados(params.id);
          } else {
            LoadingConstant.loading = false
          }
        });
      }
    })
  }
  //--
  // onSubmit(): void {
  //   this.validationForm.markAllAsTouched();
  // }

  // get select(): AbstractControl {
  //   return this.validationForm.get('select')!;
  // }
  //--
}
