import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {TipoParticipacaoService} from "../../../../_services/web/tipo-participacao.service";
import {ListaAuxiliarDto} from "../../../../_model/lista-auxiliar-dto";
import {MensagemService} from "../../../../_services/mensagem.service";
import {LoadingConstant} from "../../../../_model/loading-constant";
import {PaginatorDto} from "../../../../_componentes/tabela/tabela.component";


@Component({
  selector: 'app-lista-tipo-participacao',
  templateUrl: './lista-tipo-participacao.component.html',
  styleUrls: ['./lista-tipo-participacao.component.scss']
})
export class ListaTipoParticipacaoComponent implements OnInit {
  lista: ListaAuxiliarDto[] = [];
  icone = 'fa-info-circle';
  contextoRota = 'tipo-participacao';
  titulo = 'Tipos de Participação';
  pagina = 0;
  tamanho = 7;
  totalItens = 0;
  constructor(
    public router: Router,
    public tipoParticipacaoService: TipoParticipacaoService,
    public mensagemService: MensagemService
  ) { }

  ngOnInit(): void {
    this.listarTodos();
  }

  listarTodos(): void {
    LoadingConstant.loading = true;
    this.tipoParticipacaoService.listarTodosPaginado(this.pagina, this.tamanho).subscribe({
      next: (response) => {
        this.lista = response.content;
        this.totalItens = response.totalElements;
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    });
  }

  abrirPopupDados(id: number) {

  }

  paginacao(evento: PaginatorDto): void {
    this.pagina = evento.pagina;
    this.tamanho = evento.tamanho;
    this.listarTodos();
  }
}
