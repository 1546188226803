<app-titulo-pagina [titulo]="titulo" [contextoRota]="contextoRota" [icone]="icone" (cancelar)="finalizar()" tipoPagina="cadastro">
</app-titulo-pagina>
<div class="row pb-100">
  <div class="col-12 col-md-7 pi-0">
    <h2 class="color-primario subtitulo">{{ this.id ? 'Editar' : 'Adicionar Novo'}}</h2>
    <p class="color-texto">Preencha as informações abaixo para adicionar um novo Evento</p>

    <h3 class="color-primario">Informações gerais</h3>
    <div class="container-formulario">
      <form [formGroup]="formulario">
        <div class="row">
          <div class="form-group col-12">
            <label class="form-control-label flex justify-content-between" for="local">Local *
              <button class="bg-primario color-white" (click)="abrirLocal()">
                novo local
              </button>
            </label>
            <select class="form-control" id="local" formControlName="idLocal">
              <option *ngFor="let local of locais" [value]="local.id">{{local.descricao}}</option>
            </select>
          </div>
          <div class="form-group col-12">
            <label class="form-control-label" for="nome">Nome *</label>
            <input type="text" id="nome" class="form-control" placeholder="Nome" formControlName="nome" />
          </div>
          <div class="form-group col-12 col-md-4">
            <label class="form-control-label" for="dataInicio">Data Início *</label>
            <input type="date" id="dataInicio" class="form-control" placeholder="Data Início" formControlName="dataInicio" />
          </div>
          <div class="form-group col-12 col-md-4">
            <label class="form-control-label" for="dataFim">Data Fim *</label>
            <input type="date" id="dataFim" class="form-control" placeholder="Data Fim" formControlName="dataFim" />
          </div>
          <div class="form-group col-12 col-md-4">
            <label class="form-control-label" for="cargaHoraria">Carga Horária (em minutos)*</label>
            <input type="number" id="cargaHoraria" class="form-control" placeholder="Carga Horária" formControlName="cargaHoraria" />
          </div>
          <div class="form-group col-12 col-md-6">
            <label class="form-control-label" for="tipoEvento">Tipo de Evento *</label>
            <select class="form-control" id="tipoEvento" formControlName="idTipoEvento">
              <option *ngFor="let tipoEvento of tiposEvento" [value]="tipoEvento.id">{{tipoEvento.descricao}}</option>
            </select>
          </div>
          <div class="form-group col-12 col-md-6">
            <label class="form-control-label" for="projeto">Projeto *</label>
            <select class="form-control" id="projeto" formControlName="idProjeto">
              <option *ngFor="let projeto of projetos" [value]="projeto.id">{{projeto.nome}}</option>
            </select>
          </div>
          <div class="form-group col-12">
            <label class="form-control-label" for="descricao">Descrição</label>
            <textarea rows="4" class="form-control form-control-alternative" id="descricao" placeholder="Descrição do evento" formControlName="descricao">
            </textarea>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="col-12 col-md-7 pi-0 flex justify-content-beetwen align-items-center">
    <h5 class="color-texto mb-0 color-primario">* Campos obrigatórios</h5>
    <button class="bg-primario color-white" (click)="enviarFormulario()" [disabled]="formulario.invalid">
      adicionar
    </button>
  </div>
  <app-modal-confirmacao (ok)="confirmarFormulario()" (naoOk)="abrirModal = false" (volta)="abrirModal = false" [open]="abrirModal"></app-modal-confirmacao>
</div>
