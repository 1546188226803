<app-titulo-pagina [titulo]="titulo" [contextoRota]="contextoRota" [icone]="icone" (cancelar)="finalizar()" tipoPagina="cadastro">
</app-titulo-pagina>
<div class="row pb-100">
  <div class="col-12 col-md-7 pi-0">
    <h2 class="color-primario subtitulo">{{ this.id ? 'Editar' : 'Adicionar Novo'}}</h2>
    <p class="color-texto">Preencha as informações abaixo para adicionar um novo Tipo de Evento</p>

    <h3 class="color-primario">Informações gerais</h3>
    <div class="container-formulario">
      <form [formGroup]="formulario">
        <div class="form-group">
          <label class="form-control-label" for="descricao">Descrição *</label>
          <input type="text" id="descricao" class="form-control" placeholder="Descrição" formControlName="descricao" />
        </div>
      </form>
    </div>
  </div>
  <div class="col-12 col-md-7 flex justify-content-beetwen align-items-center pi-0">
    <h5 class="color-texto mb-0 color-primario">* Campos obrigatórios</h5>
    <button class="bg-primario color-white" (click)="enviarFormulario()" [disabled]="formulario.invalid">
      adicionar
    </button>
  </div>
  <app-modal-confirmacao (ok)="confirmarFormulario()" (naoOk)="abrirModal = false" (volta)="abrirModal = false" [open]="abrirModal"></app-modal-confirmacao>
</div>

