import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {UsuarioAutenticadoDto} from "../../_model/usuario-autenticado-dto";
import {Observable} from "rxjs";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AutenticacaoService  {
  url = environment.api.url + '/usuarios'

  constructor(
    public http: HttpClient,
    public router: Router
  ) { }

  autenticar(body: any): Observable<UsuarioAutenticadoDto> {
    return this.http.post<UsuarioAutenticadoDto>(this.url + '/autenticar/', body);
  }

  salvarStorage(usuario: UsuarioAutenticadoDto) {
    localStorage.setItem('peab', JSON.stringify(usuario));
  }

  retornarStorage(): UsuarioAutenticadoDto {
    return <UsuarioAutenticadoDto>JSON.parse(<string>localStorage.getItem('peab'));
  }

  sair() {
    localStorage.removeItem('peab');
    this.router.navigateByUrl('/login');
  }
}
