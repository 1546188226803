import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-titulo-pagina',
  templateUrl: './titulo-pagina.component.html',
  styleUrls: ['./titulo-pagina.component.scss']
})
export class TituloPaginaComponent implements OnInit {
  @Input() titulo: string = '';
  @Input() icone: string = '';
  @Input() tipoIcone = 'fas';
  @Input() contextoRota: string = '';
  @Input() tipoPagina: 'lista' | 'cadastro' | 'padrão' = 'lista';
  @Input() textoAdicionar: string = 'adicionar novo';
  @Input() exibirBotaoAdicionar = true;

  @Output() cancelar = new EventEmitter;
  constructor(
    public router: Router
  ) { }

  ngOnInit(): void {
  }

  cria() {
    this.router.navigate([`/admin/cadastros/${this.contextoRota}/novo`]);
  }

  cancela() {
    this.cancelar.emit();
  }
}
