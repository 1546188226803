import {Component, OnInit} from '@angular/core';
import {ListaAuxiliarDto} from "../../../../_model/lista-auxiliar-dto";
import {Router} from "@angular/router";
import {MensagemService} from "../../../../_services/mensagem.service";
import {LoadingConstant} from "../../../../_model/loading-constant";
import {PaginatorDto} from "../../../../_componentes/tabela/tabela.component";
import {RubricaService} from "../../../../_services/web/rubrica.service";
import {RubricaCategoriaDto} from "../../rubrica-categoria/_model/rubrica-categoria-dto";
import {forkJoin} from "rxjs";
import {RubricaCategoriaService} from "../../../../_services/web/rubrica-categoria.service";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-lista-rubrica',
  templateUrl: './lista-rubrica.component.html',
  styleUrls: ['./lista-rubrica.component.scss']
})
export class ListaRubricaComponent implements OnInit {

  listaRubrica: ListaAuxiliarDto[] = [];
  icone = 'fa-signature';
  contextoRota = 'rubrica';
  titulo = 'Rúbrica';
  pagina = 0;
  tamanho = 7;
  totalItens = 0;
  formulario = new FormGroup({
    idCategoria: new FormControl(0),
  })
  categorias: RubricaCategoriaDto[] = [];
  constructor(
    public router: Router,
    public rubricaService: RubricaService,
    public mensagemService: MensagemService,
    public categoriasRubricasService: RubricaCategoriaService
  ) { }

  ngOnInit(): void {
    this.carregarListas()
    this.listarRubrica();
  }

  listarRubrica(): void {
    LoadingConstant.loading = true;
    this.rubricaService.listarRubricaPaginado(this.pagina, this.tamanho, this.formulario.value.idCategoria).subscribe({
      next: (response) => {
        this.listaRubrica = response.content;
        this.totalItens = response.totalElements;
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    });
  }

  limparFiltros() {
    this.formulario.patchValue({
      idCategoria: 0
    });
  }

  abrirPopupDados(id: number) {

  }

  paginacao(evento: PaginatorDto): void {
    this.pagina = evento.pagina;
    this.tamanho = evento.tamanho;
    this.listarRubrica();
  }

  carregarListas(): void {
    LoadingConstant.loading = true;
    forkJoin(
      [
        this.categoriasRubricasService.listarRubricaCategoria(),
      ]
    ).subscribe({
      next: ([categorias]) => {
        this.categorias = [{id: 0, descricao: 'Selecione uma categoria'}]
        this.categorias.push(...categorias)
      }
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }

}

