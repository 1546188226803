import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {MensagemService} from "../../../../_services/mensagem.service";
import {ProjetoService} from "../../../../_services/web/projeto.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LoadingConstant} from "../../../../_model/loading-constant";
import {forkJoin} from "rxjs";
import {SolicitacaoPagamentoService} from "../../../../_services/web/solicitacao-pagamento.service";
import {RubricaProjetoService} from "../../../../_services/web/rubrica-projeto.service";
import {RubricaProjetoDto} from "../../rubrica-projeto/model/rubrica-projeto-dto";
import {ProjetoDto} from "../../projeto/model/projeto-dto";
import {SolicitacaoPagamentoDto} from "../model/solicitacao-pagamento-dto";
import {ListaAuxiliarDto} from "../../../../_model/lista-auxiliar-dto";
import {PacoteProjetoService} from "../../../../_services/web/pacote-projeto.service";
import {StringPadraoDto} from "../../../../_model/string-padrao-dto";
import {SolicitacaoPagamentoAnexoService} from "../../../../_services/web/solicitacao-pagamento-anexo.service";
import {AnexoDto} from "../model/anexo-dto";

@Component({
  selector: 'app-formulario-solicitacao-pagamento',
  templateUrl: './formulario-solicitacao-pagamento.component.html',
  styleUrls: ['./formulario-solicitacao-pagamento.component.scss']
})
export class FormularioSolicitacaoPagamentoComponent implements OnInit {
  titulo = 'Solicitações de pagamento';
  contextoRota = 'solicitacao-pagamento';
  icone = 'fa-file-invoice-dollar';
  numeroSolicitacao = '';
  id = null;
  tipoOperacao = '';
  abrirModal = false;
  valorInicialDoFormulario: any;
  novosArquivos: {descricao: string, arquivo: any}[] = [{descricao: '', arquivo: null}];
  arquivosSalvos: AnexoDto[] = [];
  indexArquivoRemover: number = 0;
  formulario = this.formBuilder.group({
    favorecido: ['', [Validators.required, Validators.maxLength(30)]],
    historico: ['', [Validators.required, Validators.maxLength(1000)]],
    idRubrica: [0, [Validators.required]],
    ordenador: [{value: '', disabled: true}],
    rubrica: [''],
    nome: [''],
    valor: [0.0, [Validators.required]],
    idProjeto: [0, [Validators.required]],
    idPacoteProjeto: [0],
    rascunho: [{value: true, disabled: true}],
    saldoDisponivel: [{value: '', disabled: true}],
    submeter: [false]
  })
  rubricas: RubricaProjetoDto[] = [];
  projetos: ProjetoDto[] = []
  pacotes: ListaAuxiliarDto[] = []
  constructor(
    public formBuilder: FormBuilder,
    public mensagemService: MensagemService,
    public projetoService: ProjetoService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public solicitacaoPagamentoService: SolicitacaoPagamentoService,
    public solicitacaoPagamentoAnexoService: SolicitacaoPagamentoAnexoService,
    public rubricaService: RubricaProjetoService,
    public pacoteProjetoService: PacoteProjetoService,
  ) {

  }

  ngOnInit(): void {
    this.carregarListas();
    this.valorInicialDoFormulario = this.formulario.value;
  }

  carregarDados(id: number | null) {
    LoadingConstant.loading = true;
    this.solicitacaoPagamentoService.buscarPeloId(id).subscribe({
      next: (response: SolicitacaoPagamentoDto) => {
        this.numeroSolicitacao = response.numero;
        this.formulario.patchValue(response);
        this.arquivosSalvos = response?.processo?.anexos;
        this.valorInicialDoFormulario = this.formulario.value;
        this.listarRubricas(response.idProjeto);
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }

  enviarFormulario() {
    this.tipoOperacao = 'salvar';
    this.abrirModal = true;
  }

  enviarRemover(evento: any) {
    this.indexArquivoRemover = evento.index;
    this.tipoOperacao = 'deletar';
    this.abrirModal = true;
  }

  enviarSubmeter() {
    this.tipoOperacao = 'submeter';
    this.abrirModal = true;
  }

  confirmarOperacao(operacao: 'submeter' | 'salvar' | 'deletar') {
    if (operacao === 'submeter') {
      this.submeter();
    } else if (operacao === 'salvar') {
      this.salvarRascunho();
    } else if (operacao === 'deletar') {
      this.removerArquivo()
    }
    this.abrirModal = false;
  }

  salvarRascunho() {
    this.cadastrarSolicitacao(false);
  }

  cadastrarSolicitacao(submeter: boolean) {
    LoadingConstant.loading = true;
    this.formulario.patchValue({
      submeter: submeter
    })
    this.solicitacaoPagamentoService.enviarFormulario(this.formulario.value, this.novosArquivos, this.id).subscribe({
      next: () => {
        this.mensagemService.sucesso();
        this.finalizar();
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }

  submeter() {
    this.cadastrarSolicitacao(true);
  }

  finalizar() {
    LoadingConstant.loading = false;
    this.router.navigate(['/admin/cadastros/solicitacao-pagamento'])
  }

  carregarListas(): void {
    LoadingConstant.loading = true;
    forkJoin(
      [
        this.projetoService.listarTodos(),
        this.pacoteProjetoService.listar()
      ]
    ).subscribe({
      next: ([projetos, pacotes]) => {
        this.projetos = projetos;
        this.pacotes = pacotes;
        this.activatedRoute.params.subscribe((params: any) => {
          if (params.id) {
            this.id = params.id;
            this.carregarDados(params.id);
          } else {
            LoadingConstant.loading = false
          }
        });
      }
    })
  }

  changeProjeto(event: any) {
    this.formulario.patchValue({
      idRubrica: null,
      ordenador: event.usuarioOrdenador,
      saldoDisponivel: null,
    })
    this.listarRubricas(event.id);
  }

  changeRubrica(event: any) {
    LoadingConstant.loading = true;
    this.rubricaService.saldoRubrica(event.id).subscribe({
      next: (response: StringPadraoDto) => {
        this.formulario.patchValue({
          saldoDisponivel: response.mensagem
        })
      }
      }).add( () => {
        LoadingConstant.loading = false;
      });
  }

  listarRubricas(id: any): void {
    LoadingConstant.loading = true;
    forkJoin(
      [
        this.rubricaService.listarTodos(id),
      ]
    ).subscribe({
      next: ([rubricas]) => {
        this.rubricas = rubricas;
        if (this.formulario.value.idRubrica) {
          this.changeRubrica({id: this.formulario.value.idRubrica})
        }
      }
    }).add( () => {
      LoadingConstant.loading = false;
    });
  }

  onFileChange(event: any, index: number) {
    this.novosArquivos[index].arquivo = event.target.files[0];
  }

  novoAnexo() {
    this.novosArquivos.push({descricao: '', arquivo: null});
  }

  removerArquivo() {
    LoadingConstant.loading = true;
    let index = this.indexArquivoRemover;
    this.solicitacaoPagamentoAnexoService.deletar(this.arquivosSalvos[index].id).subscribe({
      next: () => {
        this.mensagemService.sucesso();
        this.arquivosSalvos.splice(index, 1);
        this.carregarDados(this.id);
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add(() => {
      LoadingConstant.loading = false;
    });
  }

  anexoInvalido() {
    for (let arquivo of this.novosArquivos) {
      if (arquivo.arquivo !== null && arquivo.descricao === '') {
        return true;
      }
    }
    return false;
  }

  exibirAnexo(event: any) {
    LoadingConstant.loading = true;
    this.solicitacaoPagamentoAnexoService.buscarPeloId(event.id).subscribe({
      next: (response) => {
        const file = new Blob([response], { type: response.type });
        const url = window.URL.createObjectURL(file);
        window.open(url);
      }
    }).add(() => {
      LoadingConstant.loading = false;
    })
  }
}
