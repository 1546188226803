
<div class="modal modal-backdrop modal-loading" id="exampleModal" role="dialog" *ngIf="loading()">
  <div class="modal-dialog total-loading justify-content-center flex align-items-center h-100" role="document">

        <div class="spinner-grow  text-danger" role="status">
          <span class="sr-only">Loading...</span>
        </div>
    <div class="spinner-grow  text-danger" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow  text-danger" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow  text-danger" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
