import { Component, OnInit } from '@angular/core';
import {MensagemService} from "../../../../_services/mensagem.service";
import {LoadingConstant} from "../../../../_model/loading-constant";
import {PaginatorDto} from "../../../../_componentes/tabela/tabela.component";
import {SolicitacaoPagamentoService} from "../../../../_services/web/solicitacao-pagamento.service";
import {SolicitacaoPagamentoDto} from "../model/solicitacao-pagamento-dto";
import {AnexoDto} from "../model/anexo-dto";
import {Router} from "@angular/router";

@Component({
  selector: 'app-lista-solicitacao-pagamento',
  templateUrl: './lista-solicitacao-pagamento.component.html',
  styleUrls: ['./lista-solicitacao-pagamento.component.scss']
})
export class ListaSolicitacaoPagamentoComponent implements OnInit {
  lista: SolicitacaoPagamentoDto[] = [];
  icone = 'fa-file-invoice-dollar';
  contextoRota = 'solicitacao-pagamento';
  titulo = 'Solicitações de Pagamento';
  pagina = 0;
  tamanho = 7;
  totalItens = 0;
  outrasColunas = {campos: ['numero','projeto', 'pacoteProjeto', 'favorecido', 'valorFormatado', 'dataSolicitacao'],
        titulos: ['Número','Projeto', 'Pacote', 'Favorecido', 'Valor', 'Data da Solicitação', 'Ações']};
  itemSelecionado: any;
  abrirModal = false;
  arquivosSalvos: AnexoDto[] = [];

  constructor(
    public solicitacaoPagamentoService: SolicitacaoPagamentoService,
    public mensagemService: MensagemService,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.listarTodos();
  }

  listarTodos(): void {
    LoadingConstant.loading = true;
    this.solicitacaoPagamentoService.listarTodosPaginado(this.pagina, this.tamanho).subscribe({
      next: (response) => {
        let lista = [];
        lista.push(...response.content.map(solicitacao => (
          { ...solicitacao, exibirEditar: solicitacao.rascunho || solicitacao.revisao, alerta: solicitacao.revisao }
        )));
        this.lista = lista;
        this.totalItens = response.totalElements;
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    });
  }

  abrirVisualizarDados(id: number) {
    this.router.navigate(['/admin/cadastros/solicitacao-pagamento/visualizar/', id])
  }

  paginacao(evento: PaginatorDto): void {
    this.pagina = evento.pagina;
    this.tamanho = evento.tamanho;
    this.listarTodos();
  }
}
