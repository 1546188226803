import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {LoadingConstant} from "../../../../_model/loading-constant";
import {EmpresaService} from "../../../../_services/web/empresa.service";
import {MensagemService} from "../../../../_services/mensagem.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Uf} from "../../../../_model/uf";
import {ValidacaoService} from "../../../../_services/validacao.service";

@Component({
  selector: 'app-formulario-empresa',
  templateUrl: './formulario-empresa.component.html',
  styleUrls: ['./formulario-empresa.component.scss']
})
export class FormularioEmpresaComponent implements OnInit {
  estados = new Uf().lista;
  titulo = 'Financiadores';
  contextoRota = 'financiador';
  icone = 'fa-industry';
  id = null;
  abrirModal = false;
  formulario = this.formBuilder.group({
    cep: ['', [Validators.required]],
    cnpj: ['', [Validators.required, ValidacaoService.validaCnpj]],
    email: ['',[Validators.email,Validators.maxLength(50)]],
    uf: ['', [Validators.required]] ,
    inscricaoEstadual: ['', Validators.maxLength(9)] ,
    inscricaoMunicipal: ['', Validators.maxLength(11)],
    logradouro: ['', Validators.maxLength(100)] ,
    municipio: ['', [Validators.maxLength(60), Validators.required]],
    numero: ['', Validators.maxLength(10)],
    razaoSocial: ['', [Validators.required, Validators.maxLength(60)]],
    telefone: ['', Validators.maxLength(11)],
  });

  constructor(
    public formBuilder: FormBuilder,
    public empresaService: EmpresaService,
    public mensagemService: MensagemService,
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.id) {
        this.id = params.id;
        this.carregarDados(params.id);
      }
    });
  }

  carregarDados(id: number) {
    LoadingConstant.loading = true;
    this.empresaService.buscarPeloId(id).subscribe({
      next: (response) => {
        this.formulario.patchValue({
          cep: response.cep,
          cnpj: response.cnpj,
          email: response.email,
          uf: response.uf,
          inscricaoEstadual: response.inscricaoEstadual,
          inscricaoMunicipal: response.inscricaoMunicipal,
          logradouro: response.logradouro,
          municipio: response.municipio,
          numero: response.numero,
          razaoSocial: response.razaoSocial,
          telefone: response.telefone,
        });
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }

  enviarFormulario() {
    this.abrirModal = true;
  }

  confirmarFormulario() {
    this.abrirModal = false;
    LoadingConstant.loading = true;
    this.empresaService.enviarFormulario(this.formulario.value, this.id).subscribe({
      next: () => {
        this.mensagemService.sucesso();
        this.finalizar();
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }

  finalizar() {
    LoadingConstant.loading = false;
    this.router.navigate(['/admin/cadastros/financiador'])
  }

}
