import { Injectable } from '@angular/core';
import {filter, Observable, Subject} from "rxjs";
import {Alert, AlertType} from "../_componentes/alerta/model/alerta-dto";
import {MensagemErroDTO} from "../_componentes/alerta/model/error-dto";

@Injectable({
  providedIn: 'root'
})
export class MensagemService {
  private subject = new Subject<Alert>();
  private defaultId = 'default-alert';

  onAlert(id = this.defaultId): Observable<Alert> {
    return this.subject.asObservable().pipe(filter(x => x && x.id === id));
  }

  alert(alert: Alert) {
    alert.id = alert.id || this.defaultId;
    this.subject.next(alert);
  }

  clear(id = this.defaultId) {
    this.subject.next(new Alert({ id }));
  }

  sucesso(mensagem?: string) {
    if (!mensagem) {
      mensagem = 'Operação efetuada com sucesso';
    }
    this.alert(new Alert({type: AlertType.Success, message: mensagem, titulo: 'Sucesso' }));
  }

  error(error: MensagemErroDTO) {
    let mensagem = 'Sistema temporariamente indisponivel'
    if (error.error.message != null) {
      mensagem = error.error.message;
    }
    if (error.error.exception == "SESSAO") {
      mensagem = "Sua sessão expirou";
      // this.autenticacaoService.logout();
    }
    this.alert(new Alert({type: AlertType.Error, message: mensagem, titulo: 'Erro' }));
  }

  errorMensagem(mensagem: string) {
    this.alert(new Alert({type: AlertType.Error, message: mensagem, titulo: 'Erro' }));
  }
}
