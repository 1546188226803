import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuSuperiorComponent } from './menu-superior/menu-superior.component';
import { TabelaComponent } from './tabela/tabela.component';
import { TituloPaginaComponent } from './titulo-pagina/titulo-pagina.component';
import { MenuLateralComponent } from './menu-lateral/menu-lateral.component';
import {RouterModule} from "@angular/router";
import { ModalLoadingComponent } from './modal-loading/modal-loading.component';
import { AlertaComponent } from './alerta/alerta.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { ModalConfirmacaoComponent } from './modal-confirmacao/modal-confirmacao.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { ModalProcessoComponent } from './modal-processo/modal-processo.component';
import { TimelineComponent } from './timeline/timeline.component';



@NgModule({
  declarations: [
    MenuSuperiorComponent,
    TabelaComponent,
    TituloPaginaComponent,
    MenuLateralComponent,
    ModalLoadingComponent,
    AlertaComponent,
    PaginatorComponent,
    ModalConfirmacaoComponent,
    ModalProcessoComponent,
    TimelineComponent
  ],
  exports: [
    MenuSuperiorComponent,
    TabelaComponent,
    TituloPaginaComponent,
    MenuLateralComponent,
    ModalLoadingComponent,
    AlertaComponent,
    PaginatorComponent,
    ModalConfirmacaoComponent,
    ModalProcessoComponent,
    TimelineComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class ComponentesModule { }
