<app-titulo-pagina [titulo]="titulo" [contextoRota]="contextoRota" [icone]="icone" (cancelar)="finalizar()" tipoPagina="cadastro">
</app-titulo-pagina>
<div class="row pb-100">
  <div class="col-12 col-md-7 pi-0">
    <h2 class="color-primario subtitulo">{{ this.id ? 'Editar' : 'Adicionar Novo'}}</h2>
    <p class="color-texto">Preencha as informações abaixo para adicionar um novo Financiador</p>

    <form [formGroup]="formulario">
      <h3 class="color-primario">Informações gerais</h3>
      <div class="container-formulario row" id="informacoes-gerais">
        <div class="form-group col-12 col-md-6">
          <label class="form-control-label" for="cnpj">Cnpj *</label>
          <input type="text" mask="CPF_CNPJ" id="cnpj" class="form-control" placeholder="00.000.000/0000-00" formControlName="cnpj" />
        </div>
        <div class="form-group col-12 col-md-6">
          <label class="form-control-label" for="razaoSocial">Razão Social *</label>
          <input type="text" id="razaoSocial" class="form-control" placeholder="Razão Social" formControlName="razaoSocial" />
        </div>
        <div class="form-group col-12 col-md-6">
          <label class="form-control-label" for="inscricaoEstadual">Inscrição Estadual</label>
          <input type="text" id="inscricaoEstadual" class="form-control" placeholder="Informe a sua Inscrição Estadual" formControlName="inscricaoEstadual" />
        </div>
        <div class="form-group col-12 col-md-6">
          <label class="form-control-label" for="inscricaoMunicipal">Inscrição Municipal</label>
          <input type="text" id="inscricaoMunicipal" class="form-control" placeholder="Informe a sua Inscrição Municipal" formControlName="inscricaoMunicipal" />
        </div>
      </div>
      <h3 class="color-primario">Endereço</h3>
      <div class="container-formulario row" id="endereco">
        <div class="form-group col-12 col-md-5">
          <label class="form-control-label" for="cep">Cep </label>
          <input type="text" mask="00000-000" id="cep" class="form-control" placeholder="00.000-000" formControlName="cep" />
        </div>
        <div class="form-group col-12 col-md-2">
          <label class="form-control-label" for="estado">Estado *</label>
          <select class="form-control" id="estado" formControlName="uf">
            <option *ngFor="let uf of estados" [value]="uf.nome">{{uf.nome}}</option>
          </select>
        </div>
        <div class="form-group col-12 col-md-5">
          <label class="form-control-label" for="municipio">Município *</label>
          <input type="text" id="municipio" class="form-control" placeholder="Informe o seu Município" formControlName="municipio" />
        </div>
        <div class="form-group col-12 col-md-8">
          <label class="form-control-label" for="logradouro">Logradouro</label>
          <input type="text" id="logradouro" class="form-control" placeholder="Informe o seu Logradouro" formControlName="logradouro" />
        </div>
        <div class="form-group col-12 col-md-4">
          <label class="form-control-label" for="numero">Número</label>
          <input type="text" id="numero" class="form-control" placeholder="nº" formControlName="numero" />
        </div>
      </div>
      <h3 class="color-primario">Contatos</h3>
      <div class="container-formulario row" id="contatos">
        <div class="form-group col-12 col-md-6">
          <label class="form-control-label" for="email">Email</label>
          <input type="text" id="email" class="form-control" placeholder="Informe o seu Email" formControlName="email" />
        </div>
        <div class="form-group col-12 col-md-6">
          <label class="form-control-label" for="telefone">Telefone</label>
          <input type="text" mask="(00) 00000-0000" id="telefone" class="form-control" placeholder="(00) 00000-0000" formControlName="telefone" />
        </div>
      </div>

    </form>
  </div>
  <div class="col-12 col-md-7 pi-0 flex justify-content-beetwen align-items-center">
    <h5 class="color-texto mb-0 color-primario">* Campos obrigatórios</h5>
    <button class="bg-primario color-white" (click)="enviarFormulario()" [disabled]="formulario.invalid">
      adicionar
    </button>
  </div>
  <app-modal-confirmacao (ok)="confirmarFormulario()" (naoOk)="abrirModal = false" (volta)="abrirModal = false" [open]="abrirModal"></app-modal-confirmacao>
</div>
