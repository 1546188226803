<app-titulo-pagina [titulo]="titulo" [contextoRota]="contextoRota" [icone]="icone" [exibirBotaoAdicionar]="false" [tipoIcone]="'far'">
</app-titulo-pagina>
<div class="row">
  <div class="col-12 col-md-4 flex-column align-items-center">
    <div class="accordion" id="accordionExample">
      <div class="card">
        <div class="card-header m-0 p-0" id="headingOne">
          <h2 class="mb-0">
            <button class="btn color-primario btn-link text-left btn-block w-100 p-3 text-lg" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              <strong>Suas caixas</strong>
            </button>
          </h2>
        </div>

        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item active" aria-current="true">Pendentes ({{lista.length}})</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-8 flex-column align-items-center tabela-lista">
    <app-tabela [itens]="lista" (visualizar)="abrirPopupDados($event)" [exibirVisualizar]="true" [marginTopVisualizar]="true"
                [camposExibidos]="[{titulo: '', nome: 'nome', icone: ''}]" [espacamentoLinhas]="false" [exibirIconeVisualizar]="true"
                [paginado]="true" [tipoPaginacao]="'frontend'" [exibirUltimosAdicionados]="false" [exibirEditar]="false"
                [alternarCoresLinhas]="true">
    </app-tabela>
  </div>
  <app-modal-processo (ok)="confirmarAprovacao(null, true)" (naoOk)="confirmarAprovacao($event.justificativa, false)"
                      [arquivosSalvos]="arquivosSalvos" (volta)="abrirModal = false" [open]="abrirModal" [textoAuxiliarJustificativa]="'(Apenas para reprovação)'"
                         [textoBotaoOk]="textoBotaoOk" [textoBotaonaoOk]="textoBotaonaoOk" [solicitacao]="itemSelecionado" [exibirJustificativa]="true"
  ></app-modal-processo>
</div>

