import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {LocalService} from "../../../../_services/web/local.service";
import {LocalDto} from "../_model/local-dto";
import {MensagemService} from "../../../../_services/mensagem.service";
import {LoadingConstant} from "../../../../_model/loading-constant";
import {PaginatorDto} from "../../../../_componentes/tabela/tabela.component";

@Component({
  selector: 'app-lista-local',
  templateUrl: './lista-local.component.html',
  styleUrls: ['./lista-local.component.scss']
})
export class ListaLocalComponent implements OnInit {
  lista: LocalDto[] = [];
  icone = 'fa-map-marker-alt';
  contextoRota = 'local';
  titulo = 'Locais';
  pagina = 0;
  tamanho = 7;
  totalItens = 0;
  constructor(
    public router: Router,
    public localService: LocalService,
    public mensagemService: MensagemService
  ) { }

  ngOnInit(): void {
    this.listarLocais();
  }

listarLocais(): void {
  LoadingConstant.loading = true;
  this.localService.listarLocaisPaginado(this.pagina, this.tamanho).subscribe({
    next: (response) => {
      this.lista = response.content;
      this.totalItens = response.totalElements;
    },
    error: (error) => {
      this.mensagemService.error(error);
    }
  }).add( () => {
    LoadingConstant.loading = false;
  });
}

  abrirPopupDados(id: number) {

  }

  paginacao(evento: PaginatorDto): void {
    this.pagina = evento.pagina;
    this.tamanho = evento.tamanho;
    this.listarLocais();
  }

}
