import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {ObjetivoReferenciadoService} from "../../../../_services/web/objetivo-referenciado.service";
import {ObjetivoReferenciadoDto} from "../../objetivo-referenciado/_model/objetivo-referenciado-dto";
import {MensagemService} from "../../../../_services/mensagem.service";
import {LoadingConstant} from "../../../../_model/loading-constant";
import {PaginatorDto} from "../../../../_componentes/tabela/tabela.component";


@Component({
  selector: 'app-lista-objetivo-referenciado',
  templateUrl: './lista-objetivo-referenciado.component.html',
  styleUrls: ['./lista-objetivo-referenciado.component.scss']
})
export class ListaObjetivoReferenciadoComponent implements OnInit {
  listaObjetivoReferenciado: ObjetivoReferenciadoDto[] = [];
  icone = 'fa-info-circle';
  contextoRota = 'objetivo-referenciado';
  titulo = 'Objetivo Referenciado';
  pagina = 0;
  tamanho = 7;
  totalItens = 0;
  constructor(
    public router: Router,
    public objetivoReferenciadoService: ObjetivoReferenciadoService,
    public mensagemService: MensagemService
  ) { }

  ngOnInit(): void {
    this.listarObjetivoReferenciado();
  }

  listarObjetivoReferenciado(): void {
    LoadingConstant.loading = true;
    this.objetivoReferenciadoService.listarObjetivoReferenciadoPaginado(this.pagina, this.tamanho).subscribe({
      next: (response) => {
        this.listaObjetivoReferenciado = response.content;
        this.totalItens = response.totalElements;
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    });
  }

  abrirPopupDados(id: number) {

  }
  paginacao(evento: PaginatorDto): void {
    this.pagina = evento.pagina;
    this.tamanho = evento.tamanho;
    this.listarObjetivoReferenciado();
  }
}
