import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {StakeholderDto} from "../../admin/cadastros/stakeholder/_model/stakeholder-dto";
import {Page} from "../../_model/page-dto";
import {HttpClient, HttpParams} from "@angular/common/http";


@Injectable({
  providedIn: 'root'
})
export class StakeholderService {
  url = environment.api.url + '/stakeholder'
  constructor(
    public http: HttpClient
  ) { }
  listarStakeholderPaginado(page: number, size: number): Observable<Page<StakeholderDto>> {
    let params = new HttpParams()
      .append('page', page)
      .append('size', size);
    return this.http.get<Page<StakeholderDto>>(this.url + '/pagina-ativos', {params})
  }

  listarStakeholder(nome?: string, idEvento?: number): Observable<StakeholderDto[]> {
    let params = new HttpParams()
    if (nome) {
        params=params.append('nome', nome)
    }
    if (idEvento) {
      params = params.append('idEvento', idEvento);
    }
    return this.http.get<StakeholderDto[]>(this.url + '/lista-ativos', {params})
  }

  listarStakeholdersDoEvento(page: number, size: number, idEvento: number): Observable<Page<StakeholderDto>>{
    let params = new HttpParams()
      .append('idEvento', idEvento)
      .append('page', page)
      .append('size', size);
    return this.http.get<Page<StakeholderDto>>(this.url + '/pagina-stakeholders-por-eventos', {params})
  }


  buscarPeloId(id: number): Observable<StakeholderDto> {
    return this.http.get<StakeholderDto>(this.url + '/' + id);
  }

  enviarFormulario(body: any, id?: number | null): Observable<StakeholderDto> {
    if (id) {
      return this.http.put<StakeholderDto>(this.url + '/' + id, body);
    }
    return this.http.post<StakeholderDto>(this.url, body);
  }

  atrelaStakeholderEvento(idStakeholder: number, idEvento: number, idTipoParticipacao: number) {
    let body = {
      idStakeholder, idEvento, idTipoParticipacao
    }
    return this.http.put(this.url + '/atrela-eventos', body);
  }

  removeStakeholderEvento(idStakeholder: number, idEvento: number) {
    let body = {
      idStakeholder, idEvento
    }
    return this.http.put(this.url + '/remove-eventos', body);
  }

  downloadDocumento(idEvento: number) {
    const responseType = 'blob';
    const params = new HttpParams().append('idEvento', idEvento)
    return this.http.get(this.url + `/lista-xls`,{responseType, params});
  }
}
