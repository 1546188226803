import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {MensagemService} from "../../../../_services/mensagem.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LoadingConstant} from "../../../../_model/loading-constant";
import {RubricaCategoriaService} from "../../../../_services/web/rubrica-categoria.service";

@Component({
  selector: 'app-formulario-rubrica-categoria',
  templateUrl: './formulario-rubrica-categoria.component.html',
  styleUrls: ['./formulario-rubrica-categoria.component.scss']
})
export class FormularioRubricaCategoriaComponent implements OnInit {
  titulo = 'Categorias de Rubrica';
  contextoRota = 'rubrica-categoria';
  icone = 'fa-info-circle';
  id = null;
  isRubrica = false;
  idRubrica = null;
  abrirModal = false;
  formulario = this.formBuilder.group({
    descricao: ['', [Validators.required, Validators.maxLength(100)]],
  });

  constructor(
    public formBuilder: FormBuilder,
    public rubricaCategoriaService: RubricaCategoriaService,
    public mensagemService: MensagemService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.idRubrica) {
        this.idRubrica = params.idRubrica;
      }
      if (params.isRubrica) {
        this.isRubrica = params.isRubrica;
      }
      if (params.id) {
        this.id = params.id;
        this.carregarDados(params.id);
      }
    });
  }

  carregarDados(id: number) {
    LoadingConstant.loading = true;
    this.rubricaCategoriaService.buscarPeloId(id).subscribe({
      next: (response) => {
        this.formulario.patchValue({
          descricao: response.descricao,
        });
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add(() => {
      LoadingConstant.loading = false;
    })
  }

  enviarFormulario() {
    this.abrirModal = true;
  }

  confirmarFormulario() {
    this.abrirModal = false;
    LoadingConstant.loading = true;
    this.rubricaCategoriaService.enviarFormulario(this.formulario.value, this.id).subscribe({
      next: (response) => {
        this.mensagemService.sucesso();
        this.finalizar(response.id);
      },
      error: (error) => {
        this.mensagemService.error(error);
      },
    }).add(() => {
      LoadingConstant.loading = false;
    })
  }

  finalizar(id?: number) {
    LoadingConstant.loading = false;
    if (this.isRubrica) {
      if (this.idRubrica) {
        if (id) {
          this.router.navigate(['/admin/cadastros/rubrica/editar/rubrica-categoria/', id, this.idRubrica]);
        } else {
          this.router.navigate(['/admin/cadastros/rubrica/editar/', this.idRubrica]);
        }
      } else {
        this.router.navigate(['/admin/cadastros/rubrica/novo']);
      }
    } else {
      this.router.navigate(['/admin/cadastros/rubrica-categoria'])
    }
  }

  protected readonly confirm = confirm;
}
