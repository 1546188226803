<app-titulo-pagina [titulo]="titulo" [contextoRota]="contextoRota" [icone]="icone" (cancelar)="finalizar()" tipoPagina="cadastro">
</app-titulo-pagina>
<div class="row pb-100">
  <div class="col-12 col-md-7 pi-0">
    <h2 class="color-primario subtitulo">{{ this.id ? 'Editar' : 'Adicionar Novo'}}</h2>
    <p class="color-texto">Preencha as informações abaixo para adicionar um novo Projeto</p>

    <h3 class="color-primario">Informações gerais</h3>
    <div class="container-formulario">
      <form [formGroup]="formulario">
        <div class="row">
          <div class="form-group col-12 col-md-6">
            <label class="form-control-label" for="nome">Nome *</label>
            <input type="text" id="nome" class="form-control" placeholder="Nome" formControlName="nome" />
          </div>
          <div class="form-group col-12 col-md-6">
            <label class="form-control-label" for="nomeFantasia">Nome Fantasia</label>
            <input type="text" id="nomeFantasia" class="form-control" placeholder="Nome Fantasia" formControlName="nomeFantasia" />
          </div>
          <div class="form-group col-12 col-md-4">
            <label class="form-control-label" for="dataInicio">Data Início *</label>
            <input type="date" id="dataInicio" class="form-control" placeholder="Data Início" formControlName="dataInicio" />
          </div>
          <div class="form-group col-12 col-md-4">
            <label class="form-control-label" for="dataFim">Data Fim *</label>
            <input type="date" id="dataFim" class="form-control" placeholder="Data Fim" formControlName="dataFim" />
          </div>
          <div class="form-group col-12 col-md-4">
            <label class="form-control-label" for="orcamento">Orçamento *</label>
            <input type="text" id="orcamento" mask="separator.2" thousandSeparator="." decimalMarker=","
                   prefix="R$ " class="form-control" placeholder="Orçamento" formControlName="orcamento" />
          </div>
          <div class="form-group col-12 col-md-6">
            <label class="form-control-label" for="idFinanciador">Empresa Financiadora *</label>
            <ng-select [closeOnSelect]="false" id="idFinanciador" [items]="empresas" bindLabel="razaoSocial" bindValue="id" appendTo="body" [multiple]="true" formControlName="idFinanciador">
            </ng-select>


          </div>
          <div class="form-group col-12 col-md-6">
            <label class="form-control-label" for="idPublicoPreferencial">Público Preferencial *</label>
            <ng-select [closeOnSelect]="false" id="idPublicoPreferencial" [items]="publicosPreferenciais" bindLabel="descricao" bindValue="id" appendTo="body" [multiple]="true" formControlName="idPublicoPreferencial">
            </ng-select>
          </div>
          <div class="form-group col-12 col-md-12">
            <label class="form-control-label" for="idObjetivoReferenciado">Objetivo Referenciado *</label>
            <ng-select [closeOnSelect]="false" id="idObjetivoReferenciado" [items]="objetivosReferenciados" bindLabel="descricao" bindValue="id" appendTo="body" [multiple]="true" formControlName="idObjetivoReferenciado">
            </ng-select>
          </div>
          <div class="form-group col-12 col-md-6">
            <label class="form-control-label" for="idOrdenador">Ordenador *</label>
            <ng-select id="idOrdenador" [items]="usuarios" bindLabel="nome" bindValue="id" appendTo="body" formControlName="idUsuarioOrdenador">
            </ng-select>
          </div>
          <div class="form-group col-12 col-md-6">
            <label class="form-control-label" for="idResponsavel">Responsável *</label>
            <ng-select id="idResponsavel" [items]="usuarios" bindLabel="nome" bindValue="id" appendTo="body" formControlName="idUsuarioResponsavel">
            </ng-select>
          </div>
          <div class="form-group col-12">
            <label class="form-control-label" for="objetivo">Objetivo</label>
            <textarea rows="4" class="form-control form-control-alternative" id="objetivo" placeholder="Descreva o objetivo do projeto" formControlName="objetivo">
            </textarea>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="col-12 col-md-7 pi-0 flex justify-content-beetwen align-items-center">
    <h5 class="color-texto mb-0 color-primario">* Campos obrigatórios</h5>
    <button class="bg-primario color-white" (click)="enviarFormulario()" [disabled]="formulario.invalid">
      adicionar
    </button>
  </div>
  <app-modal-confirmacao (ok)="confirmarFormulario()" (naoOk)="abrirModal = false" (volta)="abrirModal = false" [open]="abrirModal"></app-modal-confirmacao>
</div>
