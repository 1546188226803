import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {LoadingConstant} from "../../../../_model/loading-constant";
import {UsuarioService} from "../../../../_services/web/usuario.service";
import {MensagemService} from "../../../../_services/mensagem.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-formulario-nova-senha',
  templateUrl: './formulario-nova-senha.component.html',
  styleUrls: ['./formulario-nova-senha.component.scss']
})
export class FormularioNovaSenhaComponent implements OnInit {

  titulo = 'Meus Dados';
  contextoRota = 'usuario/novasenha';
  icone = 'fa-user';
  abrirModal = false;
  formulario = this.formBuilder.group({
    senhaAtual: [''] ,
    senhaNova: ['']
  });
  constructor(
    public formBuilder: FormBuilder,
    public usuarioService: UsuarioService,
    public mensagemService: MensagemService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {

  }


  mudaSenha() {
    this.abrirModal = true;
  }

  confirmarFormulario() {
    this.abrirModal = false;
    LoadingConstant.loading = true;
    this.usuarioService.mudaSenha(this.formulario.value).subscribe({
      next: () => {
        this.mensagemService.sucesso();
        this.finalizar();
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }

  finalizar() {
    LoadingConstant.loading = false;
    this.router.navigate(['/admin/cadastros/menu'])
  }


}
