export class EventoDto {
  cargaHoraria: number
  dataFim: Date
  dataInicio: Date
  descricao: string
  id: number
  idLocal: number
  idProjeto: number
  idTipoEvento: number
  local: string
  nome: string
  projeto: string
  tipoEvento: string
  financiador: string

  constructor() {
  }
}
