<h1 class="color-primario" *ngIf="exibirUltimosAdicionados">{{textoSubtitulo}}</h1>

<div class="flex flex-column item-lista" *ngIf="outrasColunas.titulos.length > 0">
  <div class="justify-content-between w-100 row">
    <div class="align-items-center col-md text-center"  *ngFor="let coluna of outrasColunas.titulos">
      <label class="header-item-lista">{{ coluna }}</label>
    </div>
  </div>
</div>
<div class="flex flex-column item-lista"
     [ngClass]="{
     'selected-row': this.idSelecionado === item.id || (i % 2 === 0 && this.alternarCoresLinhas),
     'item-lista': this.espacamentoLinhas
     }"
     *ngFor="let item of tipoPaginacao === 'frontend' ? itensPaginados : itens; let i = index">
  <div class="flex justify-content-between w-100 row position-relative">
    <i class="fas fa-exclamation icone-medio color-warning" style="position: absolute; top: 0; left: 15px;" *ngIf="item?.alerta == true"></i>
    <div class="flex align-items-center" *ngIf="exibirDescricao">
      <i class="fas {{icone}} icone-pequeno color-primario"></i>
      <label class="header-item-lista color-primario">{{ item.titulo || item.nome || item.descricao || item.razaoSocial || item.nomeFantasia}}</label>
    </div>
    <div class="align-items-center color-primario col-md text-center" *ngFor="let coluna of outrasColunas.campos">
      <label class="header-item-lista">{{ item[coluna] }}</label>
    </div>
    <div class="col-md" [ngClass]="{'text-center': outrasColunas.campos.length > 0, 'justify-content-end flex': outrasColunas.campos.length === 0}">
      <button class="bg-branco botao-acao-lista" (click)="edita(item)" *ngIf="exibirEditar || item?.exibirEditar" data-toggle="tooltip" title="Editar item"
          [ngClass]="{'mb-2': marginBottomEditar}">
        <i class="fas fa-pen"></i>
        <span *ngIf="exibirTextoEditar" class="texto-botao">{{textoEditar}}</span>
      </button>
      <button class="bg-branco botao-acao-lista" [ngClass]="{'mt-2': marginTopVisualizar}" (click)="visualiza(item)" *ngIf="exibirVisualizar">
        <i class="far fa-eye" *ngIf="exibirIconeVisualizar"></i>
        <span class="texto-botao">{{ textoVisualizar }}</span>
      </button>
      <button class="bg-primario color-white botao-acao-lista" (click)="remove(i)" *ngIf="exibirRemover"  data-toggle="tooltip" title="Excluir item">
        <i class="fas fa-times color-white"></i>
<!--        <span class="texto-botao">remover</span>-->
      </button>
      <button class="{{item.exibirInfo ? 'bg-black' : 'bg-branco'}} botao-acao-lista" *ngIf="exibirExpandir" (click)="expandir(item.id)">
        <i class="{{item.exibirInfo ? 'fas fa-angle-double-right color-white' : 'fas fa-angle-double-down'}}"></i>
        <span class="texto-botao"></span>
      </button>
    </div>
  </div>
  <div class="flex justify-content-start flex-column color-black div-info-tabela" *ngIf="item.exibirInfo">
    <div *ngFor="let campo of camposExibidos" class="mb-1">
      <i class="{{campo.icone}} icone-pequeno"></i>
      <span class="texto-botao" *ngIf="!campo.nome.includes('data')">{{campo.titulo}} <span *ngIf="campo.titulo">:</span> {{ item[campo.nome] || 'Não Informado'}}</span>
      <span class="texto-botao" *ngIf="campo.nome.includes('data')">{{campo.titulo}}: {{ (item[campo.nome] | date : 'dd/MM/yyyy') || 'Não Informado'}}</span>
    </div>
  </div>
</div>
<app-paginator *ngIf="paginado"
    [totalElementos]="totalElementos"
               [pagina]="pagina"
               [tamanho]="tamanho"
               [exibirSelecaoTamanho]="exibirSelecaoTamanho"
               [listaTamanho]="listaTamanho"
               (paginacao)="pageEmit($event)"
></app-paginator>
