<app-titulo-pagina [titulo]="titulo" [contextoRota]="contextoRota" [icone]="icone" (cancelar)="finalizar()" tipoPagina="cadastro">
</app-titulo-pagina>
<div class="row">
  <div class="col-12 col-md-7 pi-0">
    <h2 class="color-primario subtitulo">Alterar Senha</h2>
    <p class="color-texto">Preencha as informações abaixo para alterar a sua Senha</p>

    <form [formGroup]="formulario">
      <div class="container-formulario row" id="informacoes-gerais">
        <h3 class="color-primario">Senha Atual *</h3>
        <div class="form-group col-12 col-md-12">
          <input type="password" id="nome" class="form-control" placeholder="Senha Atual" formControlName="nome" />
        </div>

        <h3 class="color-primario">Nova Senha *</h3>
        <div class="form-group col-12 col-md-12">
          <input type="password" id="nome" class="form-control" placeholder="Nova Senha" formControlName="nome" />
        </div>

        <h3 class="color-primario">Confirmar Nova Senha *</h3>
        <div class="form-group col-12 col-md-12">
          <input type="password" id="nome" class="form-control" placeholder="Confirmar Nova Senha" formControlName="nome" />
        </div>
      </div>

    </form>
  </div>
  <div class="col-12 col-md-7 pi-0 flex justify-content-beetwen align-items-center">
    <h5 class="color-texto mb-0 color-primario">* Campos obrigatórios</h5>

    <button class="bg-primario color-white" (click)="mudaSenha()" [disabled]="formulario.invalid">
      Confirmar
    </button>
  </div>
  <app-modal-confirmacao (ok)="confirmarFormulario()" (naoOk)="abrirModal = false" (volta)="abrirModal = false" [open]="abrirModal"></app-modal-confirmacao>
</div>
