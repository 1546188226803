import {Component, OnInit} from '@angular/core';
import {MensagemService} from "../../../../_services/mensagem.service";
import {ProjetoService} from "../../../../_services/web/projeto.service";
import {LoadingConstant} from "../../../../_model/loading-constant";
import {ProjetoDto} from "../../projeto/model/projeto-dto";
import {PaginatorDto} from "../../../../_componentes/tabela/tabela.component";

@Component({
  selector: 'app-lista-rubrica-projeto',
  templateUrl: './lista-rubrica-projeto.component.html',
  styleUrls: ['./lista-rubrica-projeto.component.scss']
})
export class ListaRubricaProjetoComponent implements OnInit {
  lista: ProjetoDto[] = [];
  icone = 'fa-file-contract';
  contextoRota = 'rubrica-projeto';
  titulo = 'Rúbricas de Projeto';
  pagina = 0;
  tamanho = 7;
  totalItens = 0;

  constructor(
    public projetoService: ProjetoService,
    public mensagemService: MensagemService
  ) { }

  ngOnInit(): void {
    this.listarTodos();
  }

  listarTodos(): void {
    LoadingConstant.loading = true;
    this.projetoService.listarTodosPaginado(this.pagina, this.tamanho).subscribe({
      next: (response) => {
        this.lista = response.content;
        this.totalItens = response.totalElements;
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    });
  }

  abrirPopupDados(id: number) {

  }

  paginacao(evento: PaginatorDto): void {
    this.pagina = evento.pagina;
    this.tamanho = evento.tamanho;
    this.listarTodos();
  }
}
