import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {Page} from "../../_model/page-dto";
import {RubricaProjetoDto} from "../../admin/cadastros/rubrica-projeto/model/rubrica-projeto-dto";
import {StringPadraoDto} from "../../_model/string-padrao-dto";

@Injectable({
  providedIn: 'root'
})
export class RubricaProjetoService {
  url = environment.api.url + '/rubrica-projeto'
  constructor(
    public http: HttpClient
  ) { }

  listarTodosPaginado(page: number, size: number, ordem: string, ordenacao: string, idProjeto: number): Observable<Page<RubricaProjetoDto>> {
    let params = new HttpParams()
      .append('page', page)
      .append('size', size)
      .append('sort', ordenacao + ',' + ordem)
      .append('idProjeto', idProjeto);
    return this.http.get<Page<RubricaProjetoDto>>(this.url + '/pagina-ativos', {params})
  }

  listarTodos(idProjeto: number): Observable<RubricaProjetoDto[]> {
    let params = new HttpParams()
      .append('idProjeto', idProjeto);
    return this.http.get<RubricaProjetoDto[]>(this.url + '/lista-ativos', {params})
  }

  buscarPeloId(id: number): Observable<RubricaProjetoDto> {
    return this.http.get<RubricaProjetoDto>(this.url + '/' + id);
  }

  enviarFormulario(body: any, id?: any): Observable<any> {
    if (id) {
      return this.http.put(this.url + '/' + id, body);
    }
    return this.http.post(this.url, body);
  }

  deletar(id: number): Observable<any> {
    return this.http.delete(this.url + '/' + id + '/inativar');
  }

  saldoRubrica(id: number): Observable<StringPadraoDto> {
    let params = new HttpParams()
      .append('idRubricaProjeto', id);
    return this.http.get<StringPadraoDto>(this.url + '/saldo', {params});
  }
}
