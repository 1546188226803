import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {LoadingConstant} from "../../../../_model/loading-constant";
import {TipoEventoService} from "../../../../_services/web/tipo-evento.service";
import {MensagemService} from "../../../../_services/mensagem.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-formulario-tipo-evento',
  templateUrl: './formulario-tipo-evento.component.html',
  styleUrls: ['./formulario-tipo-evento.component.scss']
})
export class FormularioTipoEventoComponent implements OnInit {
  titulo = 'Tipos de Evento';
  contextoRota = 'tipo-evento';
  icone = 'fa-info-circle';
  id = null;
  abrirModal = false;
  formulario = this.formBuilder.group({
    descricao: ['', [Validators.required, Validators.maxLength(100)]]
  });
  constructor(
    public formBuilder: FormBuilder,
    public tipoEventoService: TipoEventoService,
    public mensagemService: MensagemService,
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.id) {
        this.id = params.id;
        this.carregarDados(params.id);
      }
    });
  }

  carregarDados(id: number) {
    LoadingConstant.loading = true;
    this.tipoEventoService.buscarPeloId(id).subscribe({
      next: (response) => {
        this.formulario.patchValue({
          descricao: response.descricao
        });
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }

  enviarFormulario() {
    this.abrirModal = true;
  }

  confirmarFormulario() {
    this.abrirModal = false;
    LoadingConstant.loading = true;
    this.tipoEventoService.enviarFormulario(this.formulario.value, this.id).subscribe({
      next: () => {
        this.mensagemService.sucesso();
        this.finalizar();
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }

  finalizar() {
    LoadingConstant.loading = false;
    this.router.navigate(['/admin/cadastros/tipo-evento'])
  }
}
