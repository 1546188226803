import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {Page} from "../../_model/page-dto";
import {EventoDto} from "../../admin/cadastros/evento/model/evento-dto";

@Injectable({
  providedIn: 'root'
})
export class EventoService {
  url = environment.api.url + '/evento'
  constructor(
    public http: HttpClient
  ) { }

  listarTodosPaginado(page: number, size: number): Observable<Page<EventoDto>> {
    let params = new HttpParams()
      .append('page', page)
      .append('size', size);
    return this.http.get<Page<EventoDto>>(this.url + '/pagina-ativos', {params})
  }

  listarTodos(): Observable<EventoDto[]> {
    return this.http.get<EventoDto[]>(this.url + '/lista-ativos')
  }

  listarProximos(): Observable<EventoDto[]> {
    return this.http.get<EventoDto[]>(this.url + '/lista-eventos-proximos')
  }

  buscarPeloId(id: number): Observable<EventoDto> {
    return this.http.get<EventoDto>(this.url + '/' + id);
  }

  enviarFormulario(body: any, id?: number | null): Observable<any> {
    if (id) {
      return this.http.put(this.url + '/' + id, body);
    }
    return this.http.post(this.url, body);
  }
}
