import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {Page} from "../../_model/page-dto";
import {RubricaProjetoDto} from "../../admin/cadastros/rubrica-projeto/model/rubrica-projeto-dto";
import {SolicitacaoPagamentoDto} from "../../admin/cadastros/solicitacao-pagamento/model/solicitacao-pagamento-dto";

@Injectable({
  providedIn: 'root'
})
export class SolicitacaoPagamentoService {
  url = environment.api.url + '/solicitacao-pagamento'
  constructor(
    public http: HttpClient
  ) { }

  listarTodosPaginado(page: number, size: number): Observable<Page<SolicitacaoPagamentoDto>> {
    let params = new HttpParams()
      .append('page', page)
      .append('size', size)
    return this.http.get<Page<SolicitacaoPagamentoDto>>(this.url + '/pagina-ativos', {params})
  }

  listarTodos(idProjeto: number): Observable<SolicitacaoPagamentoDto[]> {
    let params = new HttpParams()
      .append('idProjeto', idProjeto);
    return this.http.get<SolicitacaoPagamentoDto[]>(this.url + '/lista-ativos', {params})
  }

  buscarPeloId(id: number | null): Observable<SolicitacaoPagamentoDto> {
    return this.http.get<SolicitacaoPagamentoDto>(this.url + '/' + id);
  }

  enviarFormulario(body: any, documentos: any[], id?: any): Observable<any> {
    const formData = new FormData();
    formData.append('request', new Blob([JSON.stringify(body)], { type: 'application/json' }));
    if (documentos) {
      documentos.forEach((documento) => {
        formData.append(documento.descricao, documento.arquivo);
      });
    }
    if (id) {
      return this.http.put(this.url + '/' + id, formData);
    }
    return this.http.post(this.url, formData);
  }

  deletar(id: number): Observable<any> {
    return this.http.delete(this.url + '/' + id + '/inativar');
  }

  listarPendentes(): Observable<SolicitacaoPagamentoDto[]> {
    return this.http.get<SolicitacaoPagamentoDto[]>(this.url + '/pendencias')
  }

  aprovar(id: number, aprovacao: boolean, justificativa: any): Observable<any> {
    let body: any = {
      aprovacao: aprovacao,
    }
    if (justificativa) {
      body['justificativa'] = justificativa;
    }
    return this.http.put(this.url + '/aprovar/' + id, body);
  }
}
