import { Component, OnInit } from '@angular/core';
import {MensagemService} from "../../../../_services/mensagem.service";
import {LoadingConstant} from "../../../../_model/loading-constant";
import {PaginatorDto} from "../../../../_componentes/tabela/tabela.component";
import { MunicipioDto } from '../_model/municipio-dto';
import { MunicipioService } from 'src/app/_services/web/municipio.service';
import {Router} from "@angular/router";

@Component({
  selector: 'app-lista-municipio',
  templateUrl: './lista-municipio.component.html',
  styleUrls: ['./lista-municipio.component.scss']
})
export class ListaMunicipioComponent implements OnInit {
  listaMunicipio: MunicipioDto[] = [];
  icone = 'fa-globe';
  contextoRota = 'municipio';
  titulo = 'Municípios';
  pagina = 0;
  tamanho = 7;
  totalItens = 0;

  constructor(
    public router: Router,
    public municipioService: MunicipioService,
    public mensagemService: MensagemService
  ) { }

  ngOnInit(): void {
    this.listarMunicipio();
  }

  listarMunicipio(): void {
    LoadingConstant.loading = true;
    this.municipioService.listarMunicipioPaginado(this.pagina, this.tamanho).subscribe({
      next: (response) => {
        this.listaMunicipio = response.content;
        this.totalItens = response.totalElements;
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    });
  }
  abrirPopupDados(id: number) {

  }
  paginacao(evento: PaginatorDto): void {
    this.pagina = evento.pagina;
    this.tamanho = evento.tamanho;
    this.listarMunicipio();
  }

}
