import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {LoadingConstant} from "../_model/loading-constant";
import {AutenticacaoService} from "../_services/web/autenticacao.service";
import {response} from "express";
import {MensagemService} from "../_services/mensagem.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  formulario = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    senha: ['', [Validators.required]]
  })

  constructor(
    public formBuilder: FormBuilder,
    public autenticacaoService: AutenticacaoService,
    public mensagemService: MensagemService,
    public router: Router
  ) { }

  ngOnInit(): void {
  }

  enviar(): void {
    LoadingConstant.loading = true;
    this.autenticacaoService.autenticar(this.formulario.value).subscribe({
      next: (response) => {
        this.autenticacaoService.salvarStorage(response);
        this.router.navigateByUrl('admin/cadastros/menu');
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    });
  }
}
