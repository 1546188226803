import { Component, OnInit } from '@angular/core';
import {LoadingConstant} from "../../_model/loading-constant";

@Component({
  selector: 'app-modal-loading',
  templateUrl: './modal-loading.component.html',
  styleUrls: ['./modal-loading.component.scss']
})
export class ModalLoadingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  loading(): boolean {
    return LoadingConstant.loading;
  }
}
