<ng-template id="content" #content let-modal>
<!--  <div class="modal-header">-->
<!--    <h4 class="modal-title" id="modal-basic-title">Confirmação de operação</h4>-->
<!--    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>-->
<!--  </div>-->
<!--  <div class="modal-body">-->
<!--    <form>-->
<!--      <div class="mb-3">-->
<!--        <label>Deseja confirmar a operação?</label>-->
<!--      </div>-->
<!--    </form>-->
<!--  </div>-->
<!--  <div class="modal-footer">-->
<!--    <button type="button" class="btn btn-outline-dark" (click)="confirmar(modal)">Sim</button>-->
<!--    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Não</button>-->
<!--  </div>-->

  <div class="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{titulo}}</h5>
          <button type="button" class="close" (click)="fechar(modal)" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <span [innerHTML]="texto"></span>
          <br><br><br>
          <div class="form-group col-12" *ngIf="exibirJustificativa">
            <label class="form-control-label" for="justificativa">Justificativa {{textoAuxiliarJustificativa}} *</label>
            <textarea rows="4" class="form-control form-control-alternative" id="justificativa" [(ngModel)]="justificativa">
            </textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="bg-primario color-white" (click)="confirmar(modal)">{{textoBotaoOk}}</button>
          <button type="button" class="bg-branco botao-acao-lista" (click)="cancelar(modal)"
                  [disabled]="exibirJustificativa && (justificativa == null || justificativa == '')">{{textoBotaonaoOk}}</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
