<div class="row w-100 pagina-titulo hr-bottom">
  <div class="col-12 flex flex-column flex-md-row justify-content-beetwen align-items-md-center topo-lista">
    <div class="flex align-items-center">
      <i class="{{tipoIcone}} {{icone}} icone-grande"></i>
      <h2 class="titulo-pagina color-azul-escuro">{{titulo}}</h2>
    </div>
    <div class="flex justify-content-end botao-titulo">
      <button class="bg-primario color-white" (click)="cria()" *ngIf="tipoPagina == 'lista' && exibirBotaoAdicionar">
        {{ textoAdicionar }}
      </button>
      <button class="bg-secondary color-black" (click)="cancela()" *ngIf="tipoPagina == 'cadastro'">
        cancelar
      </button>
    </div>
  </div>
</div>
