<app-titulo-pagina [titulo]="titulo" [contextoRota]="contextoRota" [icone]="icone" (cancelar)="finalizar()" tipoPagina="cadastro">
</app-titulo-pagina>
<div class="row pb-100">
  <div class="col-12 col-md-7 pi-0" id="formulario-topo">
    <h2 class="color-primario subtitulo">Solicitação de pagamento {{numeroSolicitacao}}</h2>
    <p class="color-texto">Preencha as informações abaixo para adicionar uma nova solicitação de pagamento</p>

    <h3 class="color-primario">Informações Gerais</h3>
    <div class="container-formulario">
      <form [formGroup]="formulario">

        <div class="row">
          <div class="form-group col-12 col-md-12">
            <label class="form-control-label" for="idProjeto">Projeto *</label>
            <ng-select [closeOnSelect]="true" id="idProjeto" [items]="projetos" bindLabel="nome" bindValue="id"
                       (change)="changeProjeto($event)" appendTo="body" formControlName="idProjeto" placeholder="Selecione">
            </ng-select>
          </div>
        </div>
        <div class="row" *ngIf="this.formulario.value.idProjeto != 0">
          <div class="form-group col-12 col-md-6">
            <label class="form-control-label" for="idRubrica">Rubrica *</label>
            <ng-select [closeOnSelect]="true" id="idRubrica" [items]="rubricas" bindLabel="descricao" bindValue="id"
                       (change)="changeRubrica($event)" appendTo="body" formControlName="idRubrica" placeholder="Selecione">
            </ng-select>
          </div>
          <div class="form-group col-12 col-md-6">
            <label class="form-control-label" for="saldoDisponivel">Saldo Disponível</label>
            <input type="text" [readOnly]="true" id="saldoDisponivel" class="form-control" placeholder="Saldo" formControlName="saldoDisponivel" />

          </div>
          <div class="form-group col-12 col-md-6">
            <label class="form-control-label" for="ordenador">Ordenador *</label>
            <input type="text" [readOnly]="true" id="ordenador" class="form-control" placeholder="Ordenador" formControlName="ordenador" />
          </div>

          <div class="form-group col-12 col-md-6">
            <label class="form-control-label flex justify-content-between" for="pacote">Pacote de Trabalho
            </label>
            <ng-select [closeOnSelect]="true" id="pacote" [items]="pacotes" bindLabel="descricao" bindValue="id"
                       appendTo="body" formControlName="idPacoteProjeto" placeholder="Selecione">
            </ng-select>
          </div>
          <div class="form-group col-12 col-md-6">
            <label class="form-control-label" for="favorecido">Favorecido *</label>
            <input type="text" id="favorecido" class="form-control" placeholder="Favorecido" formControlName="favorecido" />
          </div>
          <div class="form-group col-12 col-md-6">
            <label class="form-control-label" for="valor">Valor Solicitado *</label>
            <input type="text" id="valor" mask="separator.2" thousandSeparator="." decimalMarker=","
                   prefix="R$ " class="form-control" formControlName="valor" />
          </div>

          <div class="form-group col-12">
            <label class="form-control-label" for="historico">Histórico *</label>
            <textarea rows="4" class="form-control form-control-alternative" id="historico" placeholder="Histórico" formControlName="historico">
            </textarea>
          </div>


        </div>
      </form>
    </div>
    <h3 class="color-primario" *ngIf="this.formulario.value.idProjeto != 0">Anexos</h3>
    <div class="container-formulario" *ngIf="this.formulario.value.idProjeto != 0">
      <div style="text-align: end; width: 100%">
        <button class="bg-transparent color-primario" (click)="novoAnexo()">
          <i class=" icone-pequeno fas fa-plus color-primario texto-botao"></i>
          adicionar anexo
        </button>
      </div>
      <div class="row" *ngFor="let arquivo of novosArquivos; let index = index" >
        <div class="form-group col-12 col-md-6">
          <label for="arquivo-{{index}}" class="form-label">Arquivo {{index + 1}}</label>
          <input class="form-control" type="file" id="arquivo-{{index}}" (change)="onFileChange($event, index)" />
        </div>
        <div class="form-group col-12 col-md-6">
          <label for="arquivo-{{index}}" class="form-label">Descrição *</label>
          <input type="text" id="arquivo-{{index}}" class="form-control" placeholder="Descrição" [(ngModel)]="arquivo.descricao"/>
        </div>
      </div>
      <div class="row">
        <div class="col-12"  *ngIf="arquivosSalvos != null && arquivosSalvos.length > 0">
          <app-tabela [itens]="arquivosSalvos" [tipoPaginacao]="'frontend'" [exibirIconeVisualizar]="true" [exibirRemover]="true"
                      (remover)="enviarRemover($event)"
                      [paginado]="true" [exibirExpandir]="false" [exibirEditar]="false" (visualizar)="exibirAnexo($event)">
          </app-tabela>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-7 pi-0 flex justify-content-beetwen align-items-center">
    <h5 class="color-texto mb-0 color-primario">* Campos obrigatórios</h5>
    <div>
      <button class="bg-branco color-primario mr-2" (click)="enviarSubmeter()" [disabled]="formulario.invalid || this.formulario.value.rascunho == false || this.anexoInvalido()">
        submeter
      </button>
      <button class="bg-primario color-white" (click)="enviarFormulario()" [disabled]="formulario.invalid || this.formulario.value.rascunho == false || this.anexoInvalido()">
        salvar rascunho
      </button>
    </div>
  </div>
  <app-modal-confirmacao (ok)="confirmarOperacao($event.informacao)" [informacao]="tipoOperacao"
                         (naoOk)="abrirModal = false" (volta)="abrirModal = false" [open]="abrirModal"></app-modal-confirmacao></div>
