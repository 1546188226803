import { Component, OnInit } from '@angular/core';
import {SolicitacaoPagamentoDto} from "../model/solicitacao-pagamento-dto";
import {LoadingConstant} from "../../../../_model/loading-constant";
import {ActivatedRoute, Router} from "@angular/router";
import {SolicitacaoPagamentoService} from "../../../../_services/web/solicitacao-pagamento.service";
import {MensagemService} from "../../../../_services/mensagem.service";
import {AnexoDto} from "../model/anexo-dto";
import {TramiteDto} from "../../../../_model/tramite.dto";
import {SolicitacaoPagamentoAnexoService} from "../../../../_services/web/solicitacao-pagamento-anexo.service";

@Component({
  selector: 'app-visualizacao-solicitacao-pagamento',
  templateUrl: './visualizacao-solicitacao-pagamento.component.html',
  styleUrls: ['./visualizacao-solicitacao-pagamento.component.scss']
})
export class VisualizacaoSolicitacaoPagamentoComponent implements OnInit {
  solicitacao: SolicitacaoPagamentoDto;
  icone = 'fa-file-invoice-dollar';
  titulo = 'Solicitação de pagamento';
  contextoRota: 'solicitacao-pagamento';
  id = null;
  arquivosSalvos: AnexoDto[] = []
  tramites: TramiteDto[] = []

  constructor(
    public router: Router,
    public solicitacaoPagamentoService: SolicitacaoPagamentoService,
    public solicitacaoPagamentoAnexoService: SolicitacaoPagamentoAnexoService,
    public mensagemService: MensagemService,
    public activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.id) {
        this.id = params.id;
        this.carregarDados(params.id);
      } else {
        LoadingConstant.loading = false
      }
    });
  }

  carregarDados(id: number | null) {
    LoadingConstant.loading = true;
    this.solicitacaoPagamentoService.buscarPeloId(id).subscribe({
      next: (response: SolicitacaoPagamentoDto) => {
        this.solicitacao = response;
        this.arquivosSalvos = response?.processo?.anexos;
        if (response.processo && response.processo.tramites && response.processo.tramites.length > 0) {
          this.tramites = response?.processo?.tramites;
        }
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }

  finalizar() {
    LoadingConstant.loading = false;
    this.router.navigate(['/admin/cadastros/solicitacao-pagamento'])
  }

  exibirAnexo(event: any) {
    LoadingConstant.loading = true;
    this.solicitacaoPagamentoAnexoService.buscarPeloId(event.id).subscribe({
      next: (response) => {
        const file = new Blob([response], { type: response.type });
        const url = window.URL.createObjectURL(file);
        window.open(url);
      }
    }).add(() => {
      LoadingConstant.loading = false;
    })
  }

  editar() {
    this.router.navigate(['/admin/cadastros/solicitacao-pagamento/editar/', this.id])
  }
}
