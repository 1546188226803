import {Component, OnInit} from '@angular/core';
import {MensagemService} from "../../../../_services/mensagem.service";
import {LoadingConstant} from "../../../../_model/loading-constant";
import {PaginatorDto} from "../../../../_componentes/tabela/tabela.component";
import {EventoDto} from "../model/evento-dto";
import {EventoService} from "../../../../_services/web/evento.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-lista-evento',
  templateUrl: './lista-evento.component.html',
  styleUrls: ['./lista-evento.component.scss']
})
export class ListaEventoComponent implements OnInit {
  lista: EventoDto[] = [];
  icone = 'fa-comments';
  contextoRota = 'evento';
  titulo = 'Eventos';
  pagina = 0;
  tamanho = 7;
  totalItens = 0;
  camposExibidos: {icone: string, titulo: string, nome: string}[] = [
    {icone: 'fas fa-info-circle', titulo: 'Projeto', nome: 'projeto'},
    {icone: 'fas fa-info-circle', titulo: 'Tipo de Evento', nome: 'tipoEvento'},
    {icone: 'far fa-calendar-alt', titulo: 'Data Início', nome: 'dataInicio'},
    {icone: 'far fa-calendar-alt', titulo: 'Data Fim', nome: 'dataFim'}
  ];
  constructor(
    public eventoService: EventoService,
    public mensagemService: MensagemService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.listarTodos();
  }

  listarTodos(): void {
    LoadingConstant.loading = true;
    this.eventoService.listarTodosPaginado(this.pagina, this.tamanho).subscribe({
      next: (response) => {
        this.lista = response.content;
        this.totalItens = response.totalElements;
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    });
  }

  abrirPopupDados(id: number) {
    this.router.navigate(['/admin/cadastros/evento/visualizar', id])
  }

  paginacao(evento: PaginatorDto): void {
    this.pagina = evento.pagina;
    this.tamanho = evento.tamanho;
    this.listarTodos();
  }
}
