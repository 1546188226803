import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {LoadingConstant} from "../../../../_model/loading-constant";
import {StakeholderService} from "../../../../_services/web/stakeholder.service";
import {MensagemService} from "../../../../_services/mensagem.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ValidacaoService} from "../../../../_services/validacao.service";
import { MunicipioDto } from '../../municipio/_model/municipio-dto';
import { MunicipioService } from 'src/app/_services/web/municipio.service';
import {forkJoin} from "rxjs";

@Component({
  selector: 'app-formulario-stakeholder',
  templateUrl: './formulario-stakeholder.component.html',
  styleUrls: ['./formulario-stakeholder.component.scss']
})
export class FormularioStakeholderComponent implements OnInit {
  generos = [{genero: 'Masculino', value:'MASCULINO'}, {genero: 'Feminino', value:'FEMININO'}]
  titulo = 'Stakeholder';
  contextoRota = 'stakeholder';
  icone = 'fa-users';
  id = null;
  idEvento = null;
  abrirModal = false;
  formulario = this.formBuilder.group({
    nome: ['', [Validators.required, Validators.maxLength(100)]] ,
    dataNascimento: [''] ,
    nomeMae: ['', [Validators.required, Validators.maxLength(100)]] ,
    genero: [''] ,
    email: ['', [Validators.email,Validators.maxLength(50)]],
    cpf: ['', [ValidacaoService.validaCpf]],
    telefone: ['', Validators.maxLength(15)],
    observacao: ['',[Validators.maxLength(500)]],
    idLocalNascimento: [0, [Validators.required, Validators.min(1)]]
  });
  municipios: MunicipioDto[] = [];
  constructor(
    public formBuilder: FormBuilder,
    public stakeholderService: StakeholderService,
    public mensagemService: MensagemService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public municipioService: MunicipioService,
  ) { }

  ngOnInit(): void {
    this.carregarListas()
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.id) {
        this.id = params.id;
        this.carregarDados(params.id);
      }
      if (params.idEvento) {
        this.idEvento = params.idEvento;
      }
    });
  }

  carregarDados(id: number) {
    LoadingConstant.loading = true;
    this.stakeholderService.buscarPeloId(id).subscribe({
      next: (response) => {
        this.formulario.patchValue({
          nome: response.nome,
          dataNascimento: response.dataNascimento,
          nomeMae: response.nomeMae,
          genero: response.genero,
          email: response.email,
          cpf: response.cpf,
          telefone: response.telefone,
          idLocalNascimento: response.idLocalNascimento,
        });
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }

  enviarFormulario() {
    this.abrirModal = true;
  }

  confirmarFormulario() {
    this.abrirModal = false;
    LoadingConstant.loading = true;
    this.stakeholderService.enviarFormulario(this.formulario.value, this.id).subscribe({
      next: () => {
        this.mensagemService.sucesso();
        this.finalizar();
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }

  finalizar() {
    LoadingConstant.loading = false;
    if (this.idEvento) {
      this.router.navigate(['/admin/cadastros/evento/visualizar', this.idEvento])
    } else {
      this.router.navigate(['/admin/cadastros/stakeholder'])
    }
  }

  carregarListas(): void {
    LoadingConstant.loading = true;
    forkJoin(
      [this.municipioService.listarMunicipio(),
      ]
    ).subscribe({
      next: ([municipios]) => {
        this.municipios = municipios;
      },
      complete: () => {
        LoadingConstant.loading = false;
      }
    })
  }

}
