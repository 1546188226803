import {Component, OnInit} from '@angular/core';
import {MensagemService} from "../../../_services/mensagem.service";

@Component({
  selector: 'app-gerenciamento',
  templateUrl: './gerenciamento.component.html',
  styleUrls: ['./gerenciamento.component.scss']
})
export class GerenciamentoComponent implements OnInit {

  itens = [
    {
      titulo: 'Gerenciamento do Sistema', subitens: [
        {titulo: 'Local', rota: '/admin/cadastros/local', visualizar: true},
        {titulo: 'Tipo de Participação', rota: '/admin/cadastros/tipo-participacao', visualizar: true},
        {titulo: 'Público Preferencial', rota: '/admin/cadastros/publico-preferencial', visualizar: true},
        {titulo: 'Objetivo Referenciado', rota: '/admin/cadastros/objetivo-referenciado', visualizar: true},
        {titulo: 'Tipo de Evento', rota: '/admin/cadastros/tipo-evento', visualizar: true},
        {titulo: 'Municípios', rota: '/admin/cadastros/municipio', visualizar: true},
        {titulo: 'Rúbrica', rota: '/admin/cadastros/rubrica', visualizar: true},
        {titulo: 'Categoria de Rúbrica', rota: '/admin/cadastros/rubrica-categoria', visualizar: true},
      ],
    },
    {
      titulo: 'Gerenciamento de Projetos', subitens: [
        {titulo: 'Pacotes de Projeto', rota: '/admin/cadastros/pacote-projeto', visualizar: true},
        {titulo: 'Rúbricas de Projeto', rota: '/admin/cadastros/rubrica-projeto', visualizar: true},
      ],
    }

  ]
  constructor(
    public mensagemService: MensagemService,
  ) { }

  ngOnInit() {
  }

}
