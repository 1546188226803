import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-modal-confirmacao',
  templateUrl: './modal-confirmacao.component.html',
  styleUrls: ['./modal-confirmacao.component.scss']
})
export class ModalConfirmacaoComponent {

  @Output() ok: EventEmitter<any> = new EventEmitter();
  @Output() naoOk: EventEmitter<any> = new EventEmitter();
  @Output() volta: EventEmitter<any> = new EventEmitter();
  @ViewChild('content') content!: TemplateRef<any>;
  @Input() informacao: null | string = null;
  @Input() texto = 'Deseja confirmar a operação?'
  @Input() titulo = 'Confirmação de operação'

  @Input() exibirJustificativa = false;
  @Input() textoJustificativa = ''
  @Input() textoAuxiliarJustificativa = ''
  @Input() textoBotaoOk = 'Sim'
  @Input() textoBotaonaoOk = 'Não'

  justificativa = '';
  constructor(private modalService: NgbModal) {}

  @Input()
  set open(abrirModal: boolean) {
    if (abrirModal) {
      this.modalService.open(this.content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      }, (reason: any) => {
      });
    }
  }

  confirmar(modal: any) {
    modal.close();
    let body: any = {}
    if (this.exibirJustificativa) {
      body = {justificativa: this.justificativa}
    }
    if (this.informacao != null) {
      body['informacao'] = this.informacao;
      this.ok.emit(body);
    } else {
      this.ok.emit(body);
    }
  }

  cancelar(modal: any) {
    modal.close();
    let body: any = {}
    if (this.exibirJustificativa) {
      body = {justificativa: this.justificativa}
    }
    this.naoOk.emit(body);
  }

  fechar(modal: any) {
    modal.close();
    this.volta.emit();
  }
}
