import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {LoadingConstant} from "../../_model/loading-constant";
import {SolicitacaoPagamentoAnexoService} from "../../_services/web/solicitacao-pagamento-anexo.service";
import {
  AnexoDto
} from "../../admin/cadastros/solicitacao-pagamento/model/anexo-dto";
import {SolicitacaoPagamentoDto} from "../../admin/cadastros/solicitacao-pagamento/model/solicitacao-pagamento-dto";

@Component({
  selector: 'app-modal-processo',
  templateUrl: './modal-processo.component.html',
  styleUrls: ['./modal-processo.component.scss']
})
export class ModalProcessoComponent {

  @Output() ok: EventEmitter<any> = new EventEmitter();
  @Output() naoOk: EventEmitter<any> = new EventEmitter();
  @Output() volta: EventEmitter<any> = new EventEmitter();
  @ViewChild('content') content!: TemplateRef<any>;
  @Input() informacao: null | string = null;
  @Input() texto = 'Deseja confirmar a operação?'
  @Input() titulo = 'Solicitação de Pagamento'

  @Input() exibirJustificativa = false;
  @Input() textoJustificativa = ''
  @Input() textoAuxiliarJustificativa = ''
  @Input() textoBotaoOk = 'Sim'
  @Input() textoBotaonaoOk = 'Não'
  @Input() exibirBotaoOk = true
  @Input() exibirBotaoNaoOk = true
  @Input() arquivosSalvos: AnexoDto[] = [];
  @Input() solicitacao: SolicitacaoPagamentoDto;


  justificativa = '';
  constructor(private modalService: NgbModal,
              private solicitacaoPagamentoAnexoService: SolicitacaoPagamentoAnexoService) {}

  @Input()
  set open(abrirModal: boolean) {
    if (abrirModal) {
      this.modalService.open(this.content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      }, (reason: any) => {
      });
    }
  }

  confirmar(modal: any) {
    modal.close();
    let body: any = {}
    if (this.exibirJustificativa) {
      body = {justificativa: this.justificativa}
    }
    if (this.informacao != null) {
      body['informacao'] = this.informacao;
      this.ok.emit(body);
    } else {
      this.ok.emit(body);
    }
  }

  cancelar(modal: any) {
    modal.close();
    let body: any = {}
    if (this.exibirJustificativa) {
      body = {justificativa: this.justificativa}
    }
    this.naoOk.emit(body);
  }

  fechar(modal: any) {
    modal.close();
    this.volta.emit();
  }

  exibirAnexo(event: any) {
    LoadingConstant.loading = true;
    this.solicitacaoPagamentoAnexoService.buscarPeloId(event.id).subscribe({
      next: (response) => {
        const file = new Blob([response], { type: response.type });
        const url = window.URL.createObjectURL(file);
        window.open(url);
      }
    }).add(() => {
      LoadingConstant.loading = false;
    })
  }
}
