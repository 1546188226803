import { Component, OnInit } from '@angular/core';
import {LoadingConstant} from "../../../_model/loading-constant";
import {EventoService} from "../../../_services/web/evento.service";
import {EventoDto} from "../evento/model/evento-dto";
import {MensagemService} from "../../../_services/mensagem.service";
import { AutenticacaoService } from 'src/app/_services/web/autenticacao.service';
import { UsuarioAutenticadoDto } from 'src/app/_model/usuario-autenticado-dto';
import {SolicitacaoPagamentoService} from "../../../_services/web/solicitacao-pagamento.service";
import {forkJoin} from "rxjs";
import {Router} from "@angular/router";

@Component({
  selector: 'app-menu-inicial',
  templateUrl: './menu-inicial.component.html',
  styleUrls: ['./menu-inicial.component.scss']
})
export class MenuInicialComponent implements OnInit {

  itens = [
    {titulo: 'Projeto', rota: '/admin/cadastros/projeto', visualizar: true},
    {titulo: 'Evento', rota: '/admin/cadastros/evento', visualizar: true},
    {titulo: 'Stakeholder', rota: '/admin/cadastros/stakeholder', visualizar: true},
    {titulo: 'Financiadores', rota: '/admin/cadastros/financiador', visualizar: true},
    {titulo: 'Usuário', rota: '/admin/cadastros/usuario', visualizar: true},
    {titulo: 'Solicitar Pagamento', rota: '/admin/cadastros/solicitacao-pagamento', visualizar: true},
    {titulo: 'Caixa de Entrada', rota: '/admin/caixa-entrada', visualizar: true},
  ]
  eventos: EventoDto[] = [

  ]

  lista: any[] = [ ]
  usuario: UsuarioAutenticadoDto | null = null
  constructor(
    public eventoService: EventoService,
    public mensagemService: MensagemService,
    public autenticacaoService: AutenticacaoService,
    public solicitacaoPagamentoService: SolicitacaoPagamentoService,
    public router: Router
  ) { }

  ngOnInit() {
    this.listarItens();
    this.usuario = this.autenticacaoService.retornarStorage();
  }

  listarItens() {
    LoadingConstant.loading = true;
    forkJoin(
      [
        this.eventoService.listarProximos(),
        this.solicitacaoPagamentoService.listarPendentes()
      ]
    ).subscribe({
      next: ([eventos, solicitacoes]) => {
        this.eventos = eventos;
        let lista = [];
        lista.push(...solicitacoes.map(solicitacao => (
          { ...solicitacao, titulo: 'Solicitação de pagamento', exibirInfo: true, tipo: 'SOLICITACAO_PAGAMENTO' }
        )));
        this.lista = lista;
      }
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }

  abrirCaixaDeEntrada() {
    this.router.navigate(['/admin/caixa-entrada']);
  }
}
