import { Component, OnInit } from '@angular/core';
import {LoadingConstant} from "../_model/loading-constant";
import {SolicitacaoPagamentoService} from "../_services/web/solicitacao-pagamento.service";
import {MensagemService} from "../_services/mensagem.service";

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  badge: number = 0;
  constructor(
    public solicitacaoPagamentoService: SolicitacaoPagamentoService,
    public mensagemService: MensagemService
  ) { }

  ngOnInit(): void {
    this.listarPendencias();
  }

  listarPendencias(): void {
    LoadingConstant.loading = true;
    this.solicitacaoPagamentoService.listarPendentes()
      .subscribe({
        next: (response) => {
          this.badge = response.length;
        },
        error: (error) => {
          this.mensagemService.error(error);
        }
      }).add( () => {
      LoadingConstant.loading = false;
    });
  }

}
