import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';

import {AdminRoutingModule} from './admin-routing.module';
import {ListaLocalComponent} from './cadastros/local/lista-local/lista-local.component';
import {ListaTipoParticipacaoComponent} from './cadastros/tipo-participacao/lista-tipo-participacao/lista-tipo-participacao.component';
import {AdminComponent} from './admin.component';
import {ComponentesModule} from "../_componentes/componentes.module";
import {MenuInicialComponent} from './cadastros/menu-inicial/menu-inicial.component';
import {FormularioTipoParticipacaoComponent} from './cadastros/tipo-participacao/formulario-tipo-participacao/formulario-tipo-participacao.component';
import {FormularioLocalComponent} from './cadastros/local/formulario-local/formulario-local.component';
import {ListaPublicoPreferencialComponent} from './cadastros/publico-preferencial/lista-publico-preferencial/lista-publico-preferencial.component';
import {FormularioPublicoPreferencialComponent} from './cadastros/publico-preferencial/formulario-publico-preferencial/formulario-publico-preferencial.component';
import {FormularioObjetivoReferenciadoComponent} from './cadastros/objetivo-referenciado/formulario-objetivo-referenciado/formulario-objetivo-referenciado.component';
import {ListaObjetivoReferenciadoComponent} from './cadastros/objetivo-referenciado/lista-objetivo-referenciado/lista-objetivo-referenciado.component';
import {ListaTipoEventoComponent} from './cadastros/tipo-evento/lista-tipo-evento/lista-tipo-evento.component';
import {FormularioTipoEventoComponent} from './cadastros/tipo-evento/formulario-tipo-evento/formulario-tipo-evento.component';
import {FormularioEmpresaComponent} from './cadastros/empresa/formulario-empresa/formulario-empresa.component';
import {ListaEmpresaComponent} from './cadastros/empresa/lista-empresa/lista-empresa.component';
import {ListaUsuarioComponent} from './cadastros/usuario/lista-usuario/lista-usuario.component';
import {FormularioUsuarioComponent} from './cadastros/usuario/formulario-usuario/formulario-usuario.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { FormularioProjetoComponent } from './cadastros/projeto/formulario-projeto/formulario-projeto.component';
import { ListaProjetoComponent } from './cadastros/projeto/lista-projeto/lista-projeto.component';
import { FormularioStakeholderComponent } from './cadastros/stakeholder/formulario-stakeholder/formulario-stakeholder.component';
import { ListaStakeholderComponent } from './cadastros/stakeholder/lista-stakeholder/lista-stakeholder.component';
import { FormularioEventoComponent } from './cadastros/evento/formulario-evento/formulario-evento.component';
import { ListaEventoComponent } from './cadastros/evento/lista-evento/lista-evento.component';
import {NgxMaskModule} from "ngx-mask";
import {NgbAccordionModule, NgbDropdownModule} from "@ng-bootstrap/ng-bootstrap";
import { VisualizacaoEventoComponent } from './cadastros/evento/visualizacao-evento/visualizacao-evento.component';
import { NgSelectModule } from "@ng-select/ng-select";
import { FormularioNovaSenhaComponent } from './cadastros/usuario/formulario-nova-senha/formulario-nova-senha.component';
import { FormularioMunicipioComponent } from './cadastros/municipio/formulario-municipio/formulario-municipio.component';
import { ListaMunicipioComponent } from './cadastros/municipio/lista-municipio/lista-municipio.component';
import { FormularioRubricaComponent } from './cadastros/rubrica/formulario-rubrica/formulario-rubrica.component';
import { ListaRubricaComponent } from './cadastros/rubrica/lista-rubrica/lista-rubrica.component';
import { ListaRubricaCategoriaComponent } from './cadastros/rubrica-categoria/lista-rubrica-categoria/lista-rubrica-categoria.component';
import { FormularioRubricaCategoriaComponent } from './cadastros/rubrica-categoria/formulario-rubrica-categoria/formulario-rubrica-categoria.component';
import { GerenciamentoComponent } from './cadastros/gerenciamento/gerenciamento.component';
import { ListaRubricaProjetoComponent } from './cadastros/rubrica-projeto/lista-rubrica-projeto/lista-rubrica-projeto.component';
import { FormularioRubricaProjetoComponent } from './cadastros/rubrica-projeto/formulario-rubrica-projeto/formulario-rubrica-projeto.component';
import { ListaSolicitacaoPagamentoComponent } from './cadastros/solicitacao-pagamento/lista-solicitacao-pagamento/lista-solicitacao-pagamento.component';
import { FormularioSolicitacaoPagamentoComponent } from './cadastros/solicitacao-pagamento/formulario-solicitacao-pagamento/formulario-solicitacao-pagamento.component';
import { FormularioPacoteProjetoComponent } from './cadastros/pacote-projeto/formulario-pacote-projeto/formulario-pacote-projeto.component';
import { ListaPacoteProjetoComponent } from './cadastros/pacote-projeto/lista-pacote-projeto/lista-pacote-projeto.component';
import { CaixaEntradaComponent } from './caixa-entrada/caixa-entrada.component';
import { VisualizacaoSolicitacaoPagamentoComponent } from './cadastros/solicitacao-pagamento/visualizacao-solicitacao-pagamento/visualizacao-solicitacao-pagamento.component';



@NgModule({
  declarations: [
    ListaLocalComponent,
    ListaTipoParticipacaoComponent,
    AdminComponent,
    MenuInicialComponent,
    FormularioTipoParticipacaoComponent,
    FormularioLocalComponent,
    ListaPublicoPreferencialComponent,
    FormularioPublicoPreferencialComponent,
    FormularioObjetivoReferenciadoComponent,
    ListaObjetivoReferenciadoComponent,
    ListaTipoEventoComponent,
    FormularioTipoEventoComponent,
    FormularioEmpresaComponent,
    ListaEmpresaComponent,
    ListaUsuarioComponent,
    FormularioUsuarioComponent,
    FormularioProjetoComponent,
    ListaProjetoComponent,
    FormularioStakeholderComponent,
    ListaStakeholderComponent,
    FormularioEventoComponent,
    ListaEventoComponent,
    VisualizacaoEventoComponent,
    FormularioNovaSenhaComponent,
    FormularioMunicipioComponent,
    ListaMunicipioComponent,
    FormularioRubricaComponent,
    ListaRubricaComponent,
    ListaRubricaCategoriaComponent,
    FormularioRubricaCategoriaComponent,
    GerenciamentoComponent,
    ListaRubricaProjetoComponent,
    FormularioRubricaProjetoComponent,
    ListaSolicitacaoPagamentoComponent,
    FormularioSolicitacaoPagamentoComponent,
    FormularioPacoteProjetoComponent,
    ListaPacoteProjetoComponent,
    CaixaEntradaComponent,
    VisualizacaoSolicitacaoPagamentoComponent,
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    ComponentesModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    NgbDropdownModule,
    NgSelectModule,
    NgbAccordionModule,

  ],
  providers: [
    DatePipe
  ]
})
export class AdminModule { }
