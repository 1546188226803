import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PerfilService {
  url = environment.api.url + '/perfil'
  constructor(
    public http: HttpClient
  ) { }

  listarTodos(): Observable<any[]> {
    return this.http.get<any[]>(this.url + '/lista-ativos')
  }
}
