<div id="wrapper" [ngClass]="navbarToggle ? 'toggled' : 'toggled-2'">
  <div id="sidebar-wrapper" class="flex flex-column justify-content-beetwen">
    <ul class="sidebar-nav nav-pills nav-stacked" id="menu">
      <li class="flex justify-content-center align-items-center icone-topo">
        <a class="flex align-items-center icone-topo-toggled-2">
          <img src="./assets/imagens/logo-peabiru.png" class="logo" alt="logo" width="120" height="27" />
          <i class="fas fa-bars color-primario icone-medio" (click)="toggle()"></i>
        </a>
      </li>
      <li class="flex justify-content-center align-items-center outros-icones" *ngFor="let item of itens">
        <a routerLink="{{item.link}}" aria-current="page" (click)="navbarToggle ? toggle() : ''" routerLinkActive="active" class="flex justify-content-center align-items-center icones">
          <i class="{{item.tipoIcone}} {{item.icone}} color-white icone-pequeno">
            <span class="badge badge-light badge-pill" *ngIf="item.badge > 0 && badges > 0">{{badges}}</span></i>
          <span class="texto-link">{{item.texto}}</span>
        </a>
      </li>
    </ul>
    <div class="menu-lateral-usuario flex flex-column align-items-center justify-content-center">
      <div class="imagem-usuario flex">
        <i aria-hidden="true" class="fas icone-grande flex justify-content-center fa-user color-white mr-0"></i>
        <div class="pt-2 flex flex-column justify-content-center">
          <span class="texto-link"><b>{{usuario?.nome}}</b></span>
          <span class="texto-link">{{usuario?.funcao}}</span>
        </div>
      </div>
      <ul class="sidebar-nav nav-pills nav-stacked" id="menu-usuario">
        <li class="flex justify-content-center align-items-center outros-icones">
          <a (click)="sair()" class="flex justify-content-center align-items-center icones">
            <i aria-hidden="true" class="fas fa-sign-out-alt color-white"></i>
            <span class="texto-link">Sair</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
