import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {Page} from "../../_model/page-dto";
import {RubricaCategoriaDto} from "../../admin/cadastros/rubrica-categoria/_model/rubrica-categoria-dto";

@Injectable({
  providedIn: 'root'
})
export class RubricaCategoriaService {
  url = environment.api.url + '/rubrica-categoria';
  constructor(
    public http: HttpClient
  ) { }

  listarRubricaCategoriaPaginado(page: number, size: number): Observable<Page<RubricaCategoriaDto>> {
    let params = new HttpParams()
      .append('page', page)
      .append('size', size);
    return this.http.get<Page<RubricaCategoriaDto>>(this.url + '/pagina-ativos', {params})
  }

  listarRubricaCategoria(): Observable<RubricaCategoriaDto[]> {
    return this.http.get<RubricaCategoriaDto[]>(this.url + '/lista-ativos')
  }

  buscarPeloId(id: number): Observable<RubricaCategoriaDto> {
    return this.http.get<RubricaCategoriaDto>(this.url + '/' + id);
  }

  enviarFormulario(body: any, id?: number | null): Observable<any> {
    if (id) {
      return this.http.put(this.url + '/' + id, body);
    }
    return this.http.post(this.url, body);
  }
}
