import {Component, Input, OnInit} from '@angular/core';
import {TramiteDto} from "../../_model/tramite.dto";

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {
  @Input() tramites: TramiteDto[] = []
  constructor() { }

  ngOnInit(): void {
  }
}
