<form [formGroup]="formulario">
<div class="page-header flex flex-column login h-100 min-vh-100 justify-content-center align-items-center">
  <img src="./assets/imagens/logo-peabiru.png" alt="logo" class="logo"/>
  <div class="form-group">
    <label class="form-control-label color-primario" for="usuario">Usuario</label>
    <input type="email" id="usuario" class="form-control" formControlName="email"/>
  </div>
  <div class="form-group">
    <label class="form-control-label color-primario" for="senha">Senha</label>
    <input type="password" id="senha" class="form-control" formControlName="senha" (keyUp.enter)="enviar()"/>
  </div>
  <button class="color-white botao-entrar" type="button" (click)="enviar()">
    Entrar
  </button>
</div>
</form>
