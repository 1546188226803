<div class="flex justify-content-start row pagina-lista">
  <div class="col-12 col-md-8 cards flex justify-content-start pi-0"  *ngFor="let item of itens">
    <div class="align-items-center topo-lista w-100">
      <div class="titulo-dashboard flex align-items-center">
        <i class="fas fa-cog icone-grande"></i>
        <h2 class="titulo-pagina color-black"> {{item.titulo}}</h2>
      </div>
      <div class="flex justify-content-beetwen align-items-center">
        <p class="paragrafo color-texto"></p>
        <div ngbDropdown placement="bottom-right">
          <a class="btn" ngbDropdownToggle>
            <span class="admin-atalhos">administrar atalhos</span>
          </a>
          <div ngbDropdownMenu class=" dropdown-menu-right">
            <div class="dropdown-item">
              <div class="form-group form-check" *ngFor="let subitem of item.subitens">
                <input type="checkbox" class="form-check-input" id="exampleCheck1" [(ngModel)]="subitem.visualizar">
                <label class="form-check-label" for="exampleCheck1">{{subitem.titulo}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row w-100">
      <div class="col-md-4 col-sm-6 col-lg-3 col-12" *ngFor="let subitem of item.subitens" [class.display-none]="!subitem.visualizar">
        <div class="card">
          <div class="h-100 flex justify-content-center align-items-center">
            <h3 class="titulo-item">{{subitem.titulo}}</h3>
          </div>
          <a [routerLink]=subitem.rota>
            <button class="button-border">administrar</button>
          </a>
        </div>
      </div>
    </div>
  </div>


  <div class="rigth-menu">
    <!-- <p>stakeholders</p> -->
  </div>
</div>
