<app-titulo-pagina [titulo]="titulo" [contextoRota]="contextoRota" [icone]="icone" (cancelar)="finalizar()" tipoPagina="cadastro">
</app-titulo-pagina>
<div class="row pb-100">
  <div class="col-12 col-md-7 pi-0">
    <h2 class="color-primario subtitulo">{{ this.id ? 'Editar' : 'Adicionar Novo'}}</h2>
    <p class="color-texto">Preencha as informações abaixo para adicionar um novo Usuário</p>

    <form [formGroup]="formulario">
      <h3 class="color-primario">Informações gerais</h3>
      <div class="container-formulario row" id="informacoes-gerais">
        <div class="form-group col-12 col-md-6">
          <label class="form-control-label" for="nome">Nome</label>
          <input type="text" id="nome" class="form-control" placeholder="Nome" formControlName="nome" />
        </div>

        <div class="form-group col-12 col-md-6">
          <label class="form-control-label" for="email">Email</label>
          <input type="text" id="email" class="form-control" placeholder="Informe o seu Email" formControlName="email" />
        </div>
        <div class="form-group col-12 col-md-6">
          <label class="form-control-label" for="idPerfis">Perfil *</label>
          <ng-select [closeOnSelect]="false" id="idPerfis" [items]="perfis" bindLabel="nome" bindValue="id" appendTo="body" [multiple]="true"
                     formControlName="idPerfis">
          </ng-select>
        </div>
        <div class="form-group col-12 col-md-6">
          <label class="form-control-label" for="funcao">Função</label>
          <input type="text" id="funcao" class="form-control" formControlName="funcao" />
        </div>
        <div class="form-group col-12">
          <label class="form-control-label" for="funcao">O usuário é líder do grupo?</label>
          <div class="row">
            <div class="form-group col-6 col-md-3 ml-2
">
              <label class="form-check-label" for="radioLiderTrue">Sim</label>
              <input type="radio" class="form-check-input" id="radioLiderTrue" formControlName="lider" [value]="true">
            </div>
            <div class="form-group col-6 col-md-3">
              <label class="form-check-label" for="radioLiderFalse">Não</label>
              <input type="radio" class="form-check-input" id="radioLiderFalse" formControlName="lider" [value]="false">
            </div>
          </div>
        </div>
      </div>

    </form>
  </div>
  <div class="col-12 col-md-7 pi-0 flex justify-content-beetwen align-items-center">
    <h5 class="color-texto mb-0 color-primario">* Campos obrigatórios</h5>

    <button class="bg-primario color-white" (click)="enviarFormulario()" [disabled]="formulario.invalid">
      adicionar
    </button>
  </div>
  <app-modal-confirmacao (ok)="confirmarFormulario()" (naoOk)="abrirModal = false" (volta)="abrirModal = false" [open]="abrirModal"></app-modal-confirmacao>
</div>
