<app-titulo-pagina [titulo]="titulo" [contextoRota]="contextoRota" [icone]="icone">
</app-titulo-pagina>
<div class="row">
  <div class="col-12 col-md-8 flex-column align-items-center tabela-lista">
    <div class="flex flex-column item-lista">
      <div class="flex justify-content-between w-100">
        <div class="flex align-items-center" [formGroup]="formulario">
          <select class="form-control" id="categoria" formControlName="idCategoria" (change)="listarRubrica()">
            <option *ngFor="let categoria of categorias" [value]="categoria.id"
            >{{categoria.descricao}}</option>
          </select>
        </div>
        <div class="flex justify-content-end">
          <button class="bg-branco botao-acao-lista" (click)="limparFiltros()">
            <i class="fas fa-times"></i>
            <span class="texto-botao">limpar</span>
          </button>
        </div>
      </div>
    </div>
    <app-tabela [itens]="listaRubrica" (visualizar)="abrirPopupDados($event.id)" [icone]="icone" [exibirVisualizar]="false" [contextoRota]="contextoRota" [paginado]="true" [tamanho]="tamanho" [totalElementos]="totalItens" (paginacao)="paginacao($event)" [pagina]="pagina">
    </app-tabela>
  </div>
</div>
