import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PaginatorDto} from "../tabela/tabela.component";

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {
  @Input() listaTamanho: number[] = [15, 25, 100]
  @Input() pagina = 0;
  @Input() exibirSelecaoTamanho: boolean = true;
  @Output() paginacao: EventEmitter<PaginatorDto> = new EventEmitter;
  totalElementosHtml = 0;
  tamanhoHtml: number = 5;
  totalPaginas: any[] = [];
  constructor() { }

  ngOnInit(): void {
  }

  listaPaginas(): any {
    const totalPaginas = [];
    if (this.totalPaginas.length >= 7) {
      let paginaInicial = this.pagina >= 4 ? this.pagina - 2 : 1;
      totalPaginas.push(paginaInicial);
      for (let i = 0; i < 5; i++) {
        paginaInicial++;
        if (paginaInicial <= this.totalPaginas.length) {
          totalPaginas.push(paginaInicial);
        }
      }
      return totalPaginas;
    }
    return this.totalPaginas;
  }

  pageEmit() {
    this.paginacao.emit({pagina: this.pagina, tamanho: this.tamanhoHtml});
  }

  paginar(pagina: any){
    this.pagina = pagina;
    this.pageEmit();
  }

  reduzirPagina(pagina: number): void {
    if (this.pagina !== 0) {
      this.paginar(pagina);
    }
  }

  aumentarPagina(pagina: number): void {
    if (this.pagina !== this.totalPaginas.length - 1) {
      this.paginar(pagina);
    }
  }

  @Input()
  set totalElementos(totalElementos: number) {
    if (totalElementos != null) {
      this.totalElementosHtml = totalElementos;
      this.calcularTotalPaginas();
    }
  }

  @Input()
  set tamanho(size: number) {
    if (size != null) {
      this.tamanhoHtml = size;
      this.calcularTotalPaginas();
    }
  }

  calcularTotalPaginas() {
    const totalPaginas = [];
    let numero = 0;
    while (numero < this.totalElementosHtml / this.tamanhoHtml) {
      numero++;
      totalPaginas.push(numero);
    }
    if (this.tamanhoHtml * numero < this.totalElementosHtml) {
      totalPaginas.push(numero++);
    }
    this.totalPaginas = totalPaginas;
  }
}
