import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SolicitacaoPagamentoDto} from "../../admin/cadastros/solicitacao-pagamento/model/solicitacao-pagamento-dto";

@Injectable({
  providedIn: 'root'
})
export class SolicitacaoPagamentoAnexoService {
  url = environment.api.url + '/solicitacao-pagamento-anexo'
  constructor(
    public http: HttpClient
  ) { }

  buscarPeloId(id: number): Observable<any> {
    return this.http.get(this.url + '/' + id, {responseType: 'blob'});
  }

  enviarFormulario(descricao: string, documento: File, idSolicitacao: any): Observable<any> {
    const formData = new FormData();
    formData.append('descricao', descricao);
    formData.append('arquivo', documento);
    formData.append('idSolicitacaoPagamento', idSolicitacao);
    return this.http.post(this.url, formData);
  }

  deletar(id: number): Observable<any> {
    return this.http.delete(this.url + '/' + id + '/inativar');
  }
}
