<div class="flex justify-content-start flex-md-row-reverse row pagina-lista">
  <div class="col-12 col-md-4 flex-column  justify-content-start pi-0 mt-md-5">
    <div class="align-items-center topo-lista w-100">
      <div class="titulo-dashboard flex align-items-center">
        <i class="fas fa-exclamation icone-pequeno mr-1 mb-2 color-primario"></i>
        <h2 class="color-primario">Pendências</h2>
      </div>
    </div>
    <div class="row w-100">
      <div class="col-12 flex-column align-items-center tabela-lista">
        <app-tabela [itens]="lista" (visualizar)="abrirCaixaDeEntrada()" [exibirVisualizar]="true"
                    [camposExibidos]="[{titulo: '', nome: 'nome', icone: ''}]" [espacamentoLinhas]="false" [exibirIconeVisualizar]="false"
                  [paginado]="true" [tipoPaginacao]="'frontend'" [exibirUltimosAdicionados]="false" [exibirEditar]="false"
                  [alternarCoresLinhas]="true">
        </app-tabela>
      </div>
    </div>
  </div>


  <div class="col-12 col-md-8 cards flex justify-content-start pi-0">
    <div class="align-items-center topo-lista w-100">
      <div class="titulo-dashboard flex align-items-center">
        <i class="fas fa-chart-pie icone-grande"></i>
        <h2 class="titulo-pagina color-black"> Seja bem-vindo, {{usuario?.nome}}</h2>
      </div>
      <div class="flex justify-content-beetwen align-items-center">
        <p class="paragrafo color-texto">O que você gostaria de fazer agora?</p>
        <div ngbDropdown placement="bottom-right">
          <a class="btn" ngbDropdownToggle>
            <span class="admin-atalhos">administrar atalhos</span>
          </a>
          <div ngbDropdownMenu class=" dropdown-menu-right">
            <div class="dropdown-item">
              <div class="form-group form-check" *ngFor="let item of itens">
                <input type="checkbox" class="form-check-input" id="exampleCheck1" [(ngModel)]="item.visualizar">
                <label class="form-check-label" for="exampleCheck1">{{item.titulo}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row w-100">
      <div class="col-md-4 col-sm-6 col-lg-3 col-12" *ngFor="let item of itens" [class.display-none]="!item.visualizar">
        <div class="card">
          <div class="h-100 flex justify-content-center align-items-center">
            <h3 class="titulo-item">{{item.titulo}}</h3>
          </div>
          <a [routerLink]=item.rota>
            <button class="button-border">administrar</button>
          </a>
        </div>
      </div>
    </div>
    <div class="align-items-center topo-lista w-100">
      <div class="titulo-dashboard flex align-items-center">
        <h2 class="titulo-pagina color-black">Próximos Eventos</h2>
      </div>
    </div>
    <div class="row w-100">
      <div class=" col-md-4 col-sm-6 col-lg-3 col-12" *ngFor="let evento of eventos">
        <div class="card-evento">
          <div class="flex">
            <div class="flex icone-evento mt-0">
              <i aria-hidden="true" class="far icone-pequeno fa-comments color-primario icone-topo-evento"></i>
            </div>
            <div>
              <h3 class="color-primario mb-0">{{evento.nome}}</h3>
              <p class="color-texto">{{evento.descricao}}</p>
            </div>
          </div>
          <div class="flex linha-evento align-items-center">
            <div class="flex icone-evento">
              <i aria-hidden="true" class="far icone-pequeno fa-calendar color-primario m-auto"></i>
            </div>
            <span class="color-texto span-evento">{{evento.dataInicio | date: 'dd/MM/yyyy' }}</span>
          </div>
          <div class="flex linha-evento align-items-center">
            <div class="flex icone-evento">
              <i aria-hidden="true" class="fas icone-pequeno fa-map-marker-alt color-primario m-auto"></i>
            </div>
            <span class="color-texto span-evento">{{evento.local}}</span>
          </div>
          <div class="flex linha-evento align-items-center">
            <div class="flex icone-evento">
              <i aria-hidden="true" class="fas icone-pequeno fa-industry color-primario m-auto"></i>
            </div>
            <span class="color-texto span-evento">{{evento.financiador}}</span>
          </div>
          <div class="flex justify-content-center">
            <button class="bg-primario w-auto mt-3 color-white" type="button" [routerLink]="'/admin/cadastros/evento/visualizar/'+evento.id">Clique aqui</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
