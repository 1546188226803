import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {RubricaDto} from "../../rubrica/_model/rubrica-dto";
import {MensagemService} from "../../../../_services/mensagem.service";
import {ProjetoService} from "../../../../_services/web/projeto.service";
import {ActivatedRoute, Router} from "@angular/router";
import {RubricaProjetoService} from "../../../../_services/web/rubrica-projeto.service";
import {RubricaService} from "../../../../_services/web/rubrica.service";
import {LoadingConstant} from "../../../../_model/loading-constant";
import {forkJoin} from "rxjs";
import {PaginatorDto} from "../../../../_componentes/tabela/tabela.component";

@Component({
  selector: 'app-formulario-rubrica-projeto',
  templateUrl: './formulario-rubrica-projeto.component.html',
  styleUrls: ['./formulario-rubrica-projeto.component.scss']
})
export class FormularioRubricaProjetoComponent implements OnInit {
  titulo = '';
  contextoRota = 'rubrica-projeto';
  icone = 'fa-file-contract';
  id = null;
  idRubricaProjeto = null;
  abrirModal = false;
  formulario = this.formBuilder.group({
    codigo: ['', [Validators.required, Validators.maxLength(30)]],
    descricao: ['', [Validators.required, Validators.maxLength(30)]],
    idRubrica: [null, [Validators.required]],
    rubrica: [''],
    nome: [''],
    valorMensal: [0.0, [Validators.required]],
    meses: [0, [Validators.required]],
    valorTotal: [0.0, [Validators.required]],
    idProjeto: []
  })
  rubricasProjetos: any[] = [];
  rubricas: RubricaDto[] = [];
  idDeletar = null;
  operacao = '';
  ordenacoes = [
    {label: 'Código', value: 'codigo'},
    {label: 'Data de Criação', value: 'dataCriacao'},
  ];

  pagina = 0;
  tamanho = 7;
  totalItens = 0;
  ordenacao = {label: 'Data de Criação', value: 'dataCriacao'}
  ordem: 'asc' | 'desc' = 'desc'
  constructor(
    public formBuilder: FormBuilder,
    public mensagemService: MensagemService,
    public projetoService: ProjetoService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public rubricaProjetoService: RubricaProjetoService,
    public rubricaService: RubricaService
  ) {

  }

  ngOnInit(): void {
    this.carregarListas();
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.id) {
        this.id = params.id;
        this.projetoService.buscarPeloId(params.id).subscribe({
          next: (response) => {
            this.carregarDados(params.id);
            this.titulo = response.nome;
          },
          error: (error) => {
            this.mensagemService.error(error);
          }
        }).add( () => {
          LoadingConstant.loading = false;
        })
      }
    });
    this.formulario.get('valorMensal')?.valueChanges.subscribe(() => this.atualizarValorTotal());
    this.formulario.get('meses')?.valueChanges.subscribe(() => this.atualizarValorTotal());
  }

  atualizarValorTotal() {
    const valorMensal = this.formulario.get('valorMensal')?.value || 0;
    const meses = this.formulario.get('meses')?.value || 0;
    const valorTotal = valorMensal * meses;
    this.formulario.get('valorTotal')?.setValue(valorTotal, { emitEvent: false });
  }

  carregarDados(id: number) {
    LoadingConstant.loading = true;
    forkJoin(
      [
        this.rubricaProjetoService.listarTodosPaginado(this.pagina, this.tamanho, this.ordem, this.ordenacao.value, id),
      ]
    ).subscribe({
      next: ([rubricas]) => {
        this.rubricasProjetos = rubricas.content;
        this.totalItens = rubricas.totalElements;
      }
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }

  enviarFormulario() {
    this.operacao = 'salvar';
    this.abrirModal = true;
  }

  confirmarOperacao(operacao: 'deletar' | 'salvar') {
    if (operacao === 'deletar') {
      this.removerRubrica();
    } else if (operacao === 'salvar') {
      this.confirmarFormulario();
    }
    this.abrirModal = false;
  }

  confirmarFormulario() {
    LoadingConstant.loading = true;
    let formulario = this.formulario.value;
    formulario.idProjeto = this.id;
    this.rubricaProjetoService.enviarFormulario(this.formulario.value, this.idRubricaProjeto).subscribe({
      next: () => {
        this.mensagemService.sucesso();
        if (this.id != null) {
          this.carregarDados(this.id)
        }
        this.limparFormulario();
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }

  finalizar() {
    LoadingConstant.loading = false;
    this.router.navigate(['/admin/cadastros/rubrica-projeto'])
  }

  carregarListas(): void {
    LoadingConstant.loading = true;
    forkJoin(
      [
        this.rubricaService.listarRubrica()
      ]
    ).subscribe({
      next: ([rubricas]) => {
        this.rubricas = rubricas;
      }
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }

  deletarRubrica(item: any) {
    this.operacao = 'deletar';
    this.idDeletar = this.rubricasProjetos[item.index].id;
    this.abrirModal = true
  }

  editarRubrica(item: any) {
    this.formulario.patchValue(item);
    document.getElementById('formulario-topo')?.scrollIntoView({ behavior: 'smooth' });
    this.idRubricaProjeto = item.id;
  }

  cancelar() {
    this.idDeletar = null;
    this.abrirModal = false;
  }

  limparFormulario() {
    this.formulario.reset();
    this.idRubricaProjeto = null;
  }

  removerRubrica() {
    if (this.idDeletar != null) {
      this.rubricaProjetoService.deletar(this.idDeletar).subscribe({
        next: () => {
          this.mensagemService.sucesso();
          if (this.id != null) {
            this.carregarDados(this.id)
          }
        },
        error: (error) => {
          this.mensagemService.error(error);
        }
      }).add(() => {
        LoadingConstant.loading = false;
      })
    }
  }

  paginacao(evento: PaginatorDto): void {
    this.pagina = evento.pagina;
    this.tamanho = evento.tamanho;
    if (this.id) {
      this.carregarDados(this.id);
    }
  }

  alterarOrdem() {
    this.ordem = this.ordem === 'asc' ? 'desc' : 'asc';
    if (this.id) {
      this.carregarDados(this.id);
    }
  }

  alterarCampoOrdenacao(item: any) {
    this.ordenacao = item;
    if (this.id) {
      this.carregarDados(this.id);
    }
  }
}

