<app-titulo-pagina [titulo]="titulo" [contextoRota]="contextoRota" [icone]="icone" (cancelar)="finalizar()" tipoPagina="cadastro">
</app-titulo-pagina>
<div class="row pb-100">
  <div class="col-12 col-md-7 pi-0" id="formulario-topo">
    <h2 class="color-primario subtitulo">Dados da solicitação de pagamento</h2>
    <span *ngIf="solicitacao">
          <strong>Número:</strong> {{solicitacao.numero}} <br>
          <strong>Projeto:</strong> {{solicitacao.projeto}} <br>
          <strong>Valor:</strong> {{solicitacao.valorFormatado}} <br>
          <strong>Data da Solicitação:</strong> {{solicitacao.dataSolicitacao}} <br>
          <strong>Solicitante:</strong> {{solicitacao.solicitante}} <br>
          <strong>Favorecido:</strong> {{solicitacao.favorecido}} <br>
          <strong>Pacote de Trabalho:</strong> {{solicitacao.pacoteProjeto}} <br>
          <strong>Rúbrica:</strong> {{solicitacao.codigoRubrica}} - {{solicitacao.rubrica}} <br>
          <strong>Histórico:</strong> {{solicitacao.historico}} <br>
              </span>
    <span *ngIf="solicitacao && solicitacao.justificativaPendencia" style="font-size: 20px"><br>
      <strong>Motivo de pendência: </strong> {{solicitacao.justificativaPendencia}}
    </span>
    <h2 class="color-primario subtitulo">Anexos</h2>
      <app-tabela [itens]="arquivosSalvos" [tipoPaginacao]="'frontend'" [exibirIconeVisualizar]="true" [exibirRemover]="false" [exibirUltimosAdicionados]="false"
                  [paginado]="true" [exibirExpandir]="false" [exibirEditar]="false" (visualizar)="exibirAnexo($event)">
      </app-tabela>
    <div class="flex justify-content-end mr-3">
      <button class="bg-branco color-primario mr-2" (click)="finalizar()">
        voltar
      </button>
      <button class="bg-primario color-white" *ngIf="solicitacao?.rascunho == true || solicitacao?.revisao == true" (click)="editar()">
        editar
      </button>
    </div>
  </div>
  <div class="col-12 col-md-5 pi-0">
    <span *ngIf="tramites.length > 0">Trâmites</span><br><br>
    <div style="max-height: 450px; overflow-y: auto;">
      <app-timeline [tramites]="tramites"></app-timeline>
    </div>
  </div>
</div>
