<div class="flex">
  <div class="div-menu-lateral">
    <app-menu-lateral [badges]="badge"></app-menu-lateral>
  </div>
  <div class="w-100">
    <div class="flex flex-column">
      <app-menu-superior></app-menu-superior>
      <div class="corpo-paginas">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
