import {Component, Input, OnInit} from '@angular/core';
import {AutenticacaoService} from "../../_services/web/autenticacao.service";
import {UsuarioAutenticadoDto} from "../../_model/usuario-autenticado-dto";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-menu-lateral',
  templateUrl: './menu-lateral.component.html',
  styleUrls: ['./menu-lateral.component.scss']
})
export class MenuLateralComponent implements OnInit {
  navbarToggle = false;
  @Input() badges: number = 0;

  itens = [
    {link: '/admin/cadastros/menu', texto: 'Dashboard', icone: 'fa-chart-pie', tipoIcone: 'fas', badge: 0},
    {link: '/admin/caixa-entrada', texto: 'Caixa de Entrada', icone: 'fa-envelope', tipoIcone: 'fas', badge: 1},
    {link: '/admin/cadastros/evento', texto: 'Eventos', icone: 'fa-calendar-alt', tipoIcone: 'far', badge: 0},
    {link: '/admin/cadastros/projeto', texto: 'Projetos', icone: 'fa-project-diagram', tipoIcone: 'fas', badge: 0},
    {link: '/admin/cadastros/stakeholder', texto: 'Stakeholder', icone: 'fa-users', tipoIcone: 'fas', badge: 0},
    {link: '/admin/cadastros/usuario', texto: 'Usuários', icone: 'fa-user-tie', tipoIcone: 'fas', badge: 0},
    {link: '/admin/cadastros/gerenciamento', texto: 'Gerenciamento', icone: 'fa-cog', tipoIcone: 'fas', badge: 0},
    {link: '/admin/cadastros/usuario/novasenha', texto: 'Alterar Senha', icone: 'fa-key', tipoIcone: 'fas', badge: 0}

  ]
  usuario: UsuarioAutenticadoDto | null = null
  constructor(
    public autenticacaoService: AutenticacaoService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.usuario = this.autenticacaoService.retornarStorage();
  }

  toggle() {
    this.navbarToggle = !this.navbarToggle
  }

  alteraSenha(){
    this.router.navigate(['/admin/cadastros/usuario/novasenha'])
  }

  sair() {
    this.autenticacaoService.sair();
  }
}
