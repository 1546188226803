<ol class="timeline">
  <li class="passado" *ngFor="let etapa of tramites" [ngClass]="{'reprovado' : etapa.setorDestino == 'Usuário Solicitante'}">
    <strong> Data de saída:</strong> {{etapa.dataCriacao}}<br>
    <strong> Usuário / Setor: </strong> {{etapa.nomeUsuario}} / {{etapa.setorOrigem}}<br>
    <strong> Despacho: </strong> {{etapa.despacho}}
  </li>
  <li *ngIf="tramites.length > 0">
    <strong> Data de Entrada:</strong> {{tramites[tramites.length - 1].dataCriacao}}<br>
    <strong> Setor: </strong> {{tramites[tramites.length - 1].setorDestino}}
  </li>
</ol>
