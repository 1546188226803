import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AutenticacaoService} from "../../_services/web/autenticacao.service";

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    public autenticacaoService: AutenticacaoService,
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = '';
    if (this.autenticacaoService.retornarStorage()) {
      const user = this.autenticacaoService.retornarStorage();
      token = user.token;
    }
      const dupReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + token),
      });
      return next.handle(dupReq);
  }
}
