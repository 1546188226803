<ng-template id="content" #content let-modal>
  <!--  <div class="modal-header">-->
  <!--    <h4 class="modal-title" id="modal-basic-title">Confirmação de operação</h4>-->
  <!--    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>-->
  <!--  </div>-->
  <!--  <div class="modal-body">-->
  <!--    <form>-->
  <!--      <div class="mb-3">-->
  <!--        <label>Deseja confirmar a operação?</label>-->
  <!--      </div>-->
  <!--    </form>-->
  <!--  </div>-->
  <!--  <div class="modal-footer">-->
  <!--    <button type="button" class="btn btn-outline-dark" (click)="confirmar(modal)">Sim</button>-->
  <!--    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Não</button>-->
  <!--  </div>-->

  <div class="modal">
    <div class="modal-dialog dialogo-processos">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{titulo}}</h5>
          <button type="button" class="close" (click)="fechar(modal)" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 col-md-6 pt-5">
              <span>
                Dados da solicitação de pagamento <br><br>
          <strong>Número:</strong> {{solicitacao.numero}} <br>
          <strong>Projeto:</strong> {{solicitacao.projeto}} <br>
          <strong>Valor:</strong> {{solicitacao.valorFormatado}} <br>
          <strong>Data da Solicitação:</strong> {{solicitacao.dataSolicitacao}} <br>
          <strong>Solicitante:</strong> {{solicitacao.solicitante}} <br>
          <strong>Favorecido:</strong> {{solicitacao.favorecido}} <br>
          <strong>Pacote de Trabalho:</strong> {{solicitacao.pacoteProjeto}} <br>
          <strong>Rúbrica:</strong> {{solicitacao.codigoRubrica}} - {{solicitacao.rubrica}} <br>
          <strong>Histórico:</strong> {{solicitacao.historico}} <br>
              </span>
            </div>
            <div class="col-12 col-md-6 pt-5" >
              <span>Trâmites</span><br><br>
              <div style="max-height: 300px; overflow-y: auto;">
                <app-timeline [tramites]="solicitacao.processo.tramites"></app-timeline>
              </div>
            </div>
          </div>
          <br><br><br>
          <div class="form-group col-12" *ngIf="exibirJustificativa">
            <label class="form-control-label" for="justificativa">Justificativa {{textoAuxiliarJustificativa}} *</label>
            <textarea rows="4" class="form-control form-control-alternative" id="justificativa" [(ngModel)]="justificativa">
            </textarea>
          </div>
          <div class="col-12">
            <label><strong>Anexos</strong></label>
            <app-tabela [itens]="arquivosSalvos" [tipoPaginacao]="'frontend'" [exibirIconeVisualizar]="true" [exibirRemover]="false" [exibirUltimosAdicionados]="false"
                        [paginado]="true" [exibirExpandir]="false" [exibirEditar]="false" (visualizar)="exibirAnexo($event)">
            </app-tabela>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="bg-primario color-white" *ngIf="exibirBotaoOk" (click)="confirmar(modal)">{{textoBotaoOk}}</button>
          <button type="button" class="bg-branco botao-acao-lista"  *ngIf="exibirBotaoNaoOk" (click)="cancelar(modal)"
                  [disabled]="exibirJustificativa && (justificativa == null || justificativa == '')">{{textoBotaonaoOk}}</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
