import {LocalService} from './../../../../_services/web/local.service';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {LoadingConstant} from 'src/app/_model/loading-constant';
import {ActivatedRoute, Router} from '@angular/router';
import {TipoParticipacaoService} from 'src/app/_services/web/tipo-participacao.service';
import { MunicipioDto } from '../../municipio/_model/municipio-dto';
import {MensagemService} from 'src/app/_services/mensagem.service';
import {Uf} from "../../../../_model/uf";
import {forkJoin} from "rxjs";
import { MunicipioService } from 'src/app/_services/web/municipio.service';

@Component({
  selector: 'app-formulario-local',
  templateUrl: './formulario-local.component.html',
  styleUrls: ['./formulario-local.component.scss']
})
export class FormularioLocalComponent implements OnInit {
  titulo = 'Locais';
  contextoRota = 'local';
  icone = 'fa-map-marker-alt';
  id = null;
  isEvento = false;
  idEvento = null;
  abrirModal = false;
  formulario = this.formBuilder.group({
    descricao: ['', [Validators.required, Validators.maxLength(100)]],
    uf: ['', [Validators.required, Validators.maxLength(20)]],
    idMunicipio: [0, [Validators.required, Validators.min(1)]],
  });
  municipios: MunicipioDto[] = [];
  estados: any[] = new Uf().lista;
  constructor(
    public formBuilder: FormBuilder,
    public localService: LocalService,
    public mensagemService: MensagemService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public municipioService: MunicipioService,
  ) {
  }

  ngOnInit(): void {
    this.carregarListas()
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.idEvento) {
        this.idEvento = params.idEvento;
      }
      if (params.isEvento) {
        this.isEvento = params.isEvento;
      }
      if (params.id) {
        this.id = params.id;
        this.carregarDados(params.id);
      }
    });
  }

  carregarDados(id: number) {
    LoadingConstant.loading = true;
    this.localService.buscarPeloId(id).subscribe({
      next: (response) => {
        this.formulario.patchValue({
          idMunicipio: response.municipio,
          uf: response.uf,
          descricao: response.descricao,
        });
      },
      error: (error) => {
        this.mensagemService.error(error);
      }
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }

  enviarFormulario() {
    this.abrirModal = true;
  }

  confirmarFormulario() {
    this.abrirModal = false;
    LoadingConstant.loading = true;
    this.localService.enviarFormulario(this.formulario.value, this.id).subscribe({
      next: (response) => {
        this.mensagemService.sucesso();
        this.finalizar(response.id);
      },
      error: (error) => {
        this.mensagemService.error(error);
      },
    }).add( () => {
      LoadingConstant.loading = false;
    })
  }
  carregarListas(): void {
    LoadingConstant.loading = true;
    forkJoin(
      [this.municipioService.listarMunicipio(),
      ]
    ).subscribe({
      next: ([municipios]) => {
        this.municipios = municipios;
      },
      complete: () => {
        LoadingConstant.loading = false;
      }
    })
  }

  finalizar(id?: number) {
    LoadingConstant.loading = false;
    if (this.isEvento) {
      if (this.idEvento) {
        if (id) {
          this.router.navigate(['/admin/cadastros/evento/editar/local/', id, this.idEvento]);
        } else {
          this.router.navigate(['/admin/cadastros/evento/editar/', this.idEvento]);
        }
      } else {
        this.router.navigate(['/admin/cadastros/evento/novo']);
      }
    } else {
      this.router.navigate(['/admin/cadastros/local'])
    }
  }
}
